import { useMutation, useQueryClient } from "react-query"
import { useQuery } from "react-query"

import { api } from "../../api"
import { httpClient } from "../../http-helpers"

const getNepseSummartHandler = () => () => {
  return httpClient.get(api.nepse.getNepseSummary)
}
const getAllStocksInNepseHandler = () => () => {
  return httpClient.get(api.nepse.getAllStocksInNepse)
}

export const useGetNepseSummary = () => {
  return useQuery(api.nepse.getNepseSummary, getNepseSummartHandler(), {
    select: response => response.data,
    onError: error => {
      //   toastFail(error?.response?.data?.message || "Something Went Wrong");
    },
  })
}

export const useAllStocksInNepse = () => {
  return useQuery(api.nepse.getAllStocksInNepse, getAllStocksInNepseHandler(), {
    select: response => response.data,
    onError: error => {},
  })
}
