import { QueryClient, useMutation, useQueryClient } from "react-query"
import { useQuery } from "react-query"
import { toast } from "react-toastify"

import { api } from "../../api"
import { httpClient } from "../../http-helpers"

const getDashboardSummery = () => () => {
  return httpClient.get(api.pmsService.pmsReport.getSymmurayByAdmin)
}
const getPmsReportByUserId = userId => () => {
  return httpClient.get(api.pmsService.pmsReport.getSymmurayByAdmin, {
    params: {
      userId: userId,
    },
  })
}

export const useGetDashboardSummery = () => {
  return useQuery(
    api.pmsService.pmsReport.getSymmurayByAdmin,
    getDashboardSummery(),
    {
      select: response => response.data,
      onError: error => {
        //   toastFail(error?.response?.data?.message || "Something Went Wrong");
      },
    }
  )
}
export const usePmsReportByUserId = userId => {
  return useQuery(
    [api.pmsService.pmsReport.getSymmurayByAdmin, userId],
    getPmsReportByUserId(userId),
    {
      enabled: !!userId,
      select: response => response.data,
      onError: error => {
        //   toastFail(error?.response?.data?.message || "Something Went Wrong");
      },
    }
  )
}
