import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useForm } from "react-hook-form"
import { setBreadcrumbItems } from "../../../store/actions"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"

import { toast } from "react-toastify"
import Select from "react-select"
import { useAllStocksInNepse } from "services/fetchers/nepse/nepse"
import { useGetProtfolioByScript } from "services/fetchers/pmsservice/pmsProtfolio"

function AllocationSearch(props) {
  const breadcrumbItems = [
    { title: "Home", link: "/" },
    { title: "Allocations", link: "/allocations" },
  ]
  useEffect(() => {
    props.setBreadcrumbItems("Modals", breadcrumbItems)
  })
  const [selectedOption, setSelectedOption] = useState("")
  const [stocksDropdownList, setStocksDropDownList] = useState([])
  const allStocksInNepse = useAllStocksInNepse()
  useEffect(() => {
    if (allStocksInNepse?.data?.data?.length > 0) {
      const tempArray = []
      allStocksInNepse?.data?.data.forEach(element => {
        const tempDict = {}
        tempDict.label = element.symbol
        tempDict.value = element.symbol
        tempArray.push(tempDict)
      })
      setStocksDropDownList(() => tempArray)
    }
  }, [allStocksInNepse?.data?.data])

  const [data, setData] = useState({
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Script",
        field: "script",
        sort: "asc",
        width: 270,
      },
      {
        label: "Unit",
        field: "unit",
        sort: "asc",
        width: 200,
      },
      {
        label: "WACC",
        field: "wacc",
        sort: "asc",
        width: 100,
      },
      {
        label: "LTP",
        field: "ltp",
        sort: "asc",
        width: 150,
      },
      {
        label: "Realized Gain",
        field: "realizedGain",
        sort: "asc",
        width: 100,
      },
      {
        label: "Unrealized Gain",
        field: "unrealizedGain",
        sort: "asc",
        width: 100,
      },
      {
        label: "Net P/L",
        field: "netPL",
        sort: "asc",
        width: 100,
      },
      {
        label: "Commission",
        field: "commission",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "Details",
      //   field: "details",
      //   sort: "asc",
      //   width: 100,
      // },
    ],
    rows: [],
  })

  console.log(selectedOption?.value)
  const allocationOfSelectedScript = useGetProtfolioByScript(
    selectedOption?.value
  )
  useEffect(() => {
    if (allocationOfSelectedScript?.data?.data) {
      if (allocationOfSelectedScript?.data?.data?.length > 0) {
        const tempArray = []
        allocationOfSelectedScript?.data?.data.forEach(element => {
          const tempFullName = element.json_agg[0].user["middle_name"]
            ? element.json_agg[0].user["first_name"] +
              " " +
              element.json_agg[0].user["middle_name"] +
              " " +
              element.json_agg[0].user["last_name"]
            : element.json_agg[0].user["first_name"] +
              " " +
              element.json_agg[0].user["last_name"]
          const ltpTemp =
            allStocksInNepse?.data?.data[
              allStocksInNepse?.data?.data.findIndex(
                x => x.symbol == element.stock_id
              )
            ]?.close
          const tempDict = {
            name: tempFullName,
            script: element.stock_id,

            unit: Number(element.quantity).toLocaleString(),
            wacc: Number(element.avg_cost).toLocaleString(),
            ltp: Number(ltpTemp).toLocaleString(),
            netPL: Number(element.net_profit).toLocaleString(),
            commission: Number(element.pms_commission).toLocaleString(),
            details: "2011/04/25",
            realizedGain: Number(element.realized_gain).toLocaleString(),
            unrealizedGain: Number(element.unrealized_gain).toLocaleString(),
          }
          tempArray.push(tempDict)
        })

        const newData = {
          ...data,
          rows: tempArray,
        }

        setData(newData)
      } else {
        const newData = {
          ...data,
          rows: [],
        }

        setData(newData)
      }
    }
  }, [allocationOfSelectedScript?.data])

  return (
    <div>
      <Card>
        <CardHeader className="bg-primary text-white">
          Search By Scripts
        </CardHeader>
        <CardBody className="row">
          <div className="p-2">
            <Row>
              <Col xl={3} sm={6}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={stocksDropdownList}
                />
              </Col>
            </Row>
          </div>
          <Row className="p-2">
            <Col xl={12} sm={12} id="data-table-id" key={selectedOption.value}>
              <MDBDataTable
                key={selectedOption.value}
                responsive
                striped
                noBottomColumns={true}
                data={data}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}
export default connect(null, { setBreadcrumbItems })(AllocationSearch)
