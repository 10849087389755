import React from "react"
import { createRoot } from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import { QueryClient, QueryClientProvider } from "react-query"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import store from "./store"
import "index.css"
import { PrimeReactProvider } from "primereact/api"
import "primereact/resources/themes/saga-blue/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 3 * 60 * 1000,
    },
  },
})

const container = document.getElementById("root")
const root = createRoot(container) // Create a root.
const value = {
  cssTransition: false,
}
const app = (
  <PrimeReactProvider value={value}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <ToastContainer theme="colored" />
          <App />
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </PrimeReactProvider>
)

root.render(app) // Render the app to the root.
serviceWorker.unregister()
