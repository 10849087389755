import { QueryClient, useMutation, useQueryClient } from "react-query"
import { useQuery } from "react-query"
import { toast } from "react-toastify"

import { api } from "../../api"
import { httpClient } from "../../http-helpers"

const getProtfolioByScript = script => () => {
  return httpClient.get(api.pmsService.pmsProtfolio.getAllProtfolioAdmin, {
    params: { scrip: script },
  })
}
const getUserProtfolioById = userId => () => {
  return httpClient.get(
    api.pmsService.pmsProtfolio.getProtfolioByUserId.replace("{id}", userId)
  )
}
const getAdminGraph = () => {
  return httpClient.get(api.pmsService.pmsProtfolio.getProtfolioGraph)
}

export const useGetProtfolioByScript = script => {
  return useQuery(
    [api.pmsService.pmsProtfolio.getAllProtfolioAdmin, script],
    getProtfolioByScript(script),
    {
      enabled: !!script, // lazy query i.e. script na aako bela samma trigger hunna
      select: response => response.data,
      onError: error => {},
    }
  )
}

export const useGetProtfolioByUserId = userId => {
  return useQuery(
    [api.pmsService.pmsProtfolio.getProtfolioByUserId, userId],
    getUserProtfolioById(userId),
    {
      select: response => response.data,
      onError: error => {},
    }
  )
}

export const useGetProtfolioGraphBySuperAdmin = () => {
  return useQuery(
    [api.pmsService.pmsProtfolio.getProtfolioGraph],
    getAdminGraph,
    {
      select: response => response.data,
      onError: error => {},
    }
  )
}
