import React from "react"
import CommonBasicTable from "components/UI/CommonBasicTable"
import { Link } from "react-router-dom"

function WithdrawDetails() {
  const columnName = {
    sectorName: "Sector Name",
    symbol: "Symbol",
    stockName: "Stock Name",
    avgRate: "Avg.Rate",
    amount: "Closing Price",
    liquidFund: "Amount",
  }
  const clientData = [
    {
      sectorName: (
        <Link to="/clients/name">
          <div className="text-dark">sectorName</div>
        </Link>
      ),
      symbol: "symbol",
      stockName: "stockName",
      avgRate: 25,
      closingPrice: 25000,
      amount: 250,
      liquidFund: 20,
    },
    {
      sectorName: (
        <Link to="/clients/name">
          <div className="text-dark">sectorName</div>
        </Link>
      ),
      symbol: "symbol",
      stockName: "stockName",
      avgRate: 25,
      closingPrice: 25000,
      amount: 250,
      liquidFund: 20,
    },
    {
      sectorName: (
        <Link to="/clients/name">
          <div className="text-dark">sectorName</div>
        </Link>
      ),
      symbol: "symbol",
      stockName: "stockName",
      avgRate: 25,
      closingPrice: 25000,
      amount: 250,
      liquidFund: 20,
    },
  ]
  return (
    <div className="table-responsive pt-2">
      <div className="table-header text-center ">Dividend Announcement</div>

      <CommonBasicTable data={clientData} columnParser={columnName} />
    </div>
  )
}

export default WithdrawDetails
