import LabelNameToInputMapper from "components/Common/LabelNameToInputMapper"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../../store/actions"
import BtnNameToButtonMapper from "components/Common/BtnNameToButtonMapper"
import { Card, CardBody } from "reactstrap"
import { useForm, FormProvider } from "react-hook-form"
import {
  Stepper,
  Step,
  StepButton,
  Box,
  Button,
  Typography,
} from "@mui/material"
import PersonalInfo from "./personalInfo"
import InvestmentInfo from "./investmentInfo"
import BankAccountInfo from "./bankAccounts"

import Documents from "./documents"

const stepComponents = [
  { label: "Personal Details", component: PersonalInfo },
  { label: "Investment Details", component: InvestmentInfo },
  // { label: "Accounts Details", component: AccountInfo },
  { label: "Bank Accounts Details", component: BankAccountInfo },
  { label: "Documents", component: Documents },
]

function ClientAdd() {
  const methods = useForm()
  // const [currentAuthorization, setCurrentAuthorization] = useState("")
  // const [currntSelectedValue, setCurrntSelectedValue] = useState(" ")
  const [showModal, setShowModal] = useState("")
  // const [formReturnData, setFormReturnData] = useState("")
  const [isModalShow, setIsModalShow] = useState(false)
  // const [btnName, setBtnName] = useState([
  //   { name: "cancel", color: "danger", type: "cancel" },
  //   { name: "Save & Publish", color: "success", type: "submit" },
  // ])
  // useEffect(() => {
  //   if (currentAuthorization == "") {
  //     setBtnName([
  //       { name: "Cancel", color: "danger", type: "cancel" },
  //       { name: "Save & Publish", color: "success", type: "submit" },
  //     ])
  //   }
  //   if (currentAuthorization == "read") {
  //     setBtnName([{ name: "Cancel", color: "danger", type: "cancel" }])
  //   }
  //   if (currentAuthorization == "update") {
  //     setBtnName([
  //       { name: "Cancel", color: "danger", type: "cancel" },
  //       { name: "Update & Publish", color: "primary", type: "submit" },
  //     ])
  //   }
  //   if (currentAuthorization == "delete") {
  //     setBtnName([
  //       { name: "cancel", color: "primary", type: "cancel" },
  //       { name: "Delete", color: "danger", type: "submit" },
  //     ])
  //   }
  // }, [currentAuthorization])
  // const labelNames = [
  //   {
  //     name: "Promo Code",
  //     formName: "code",
  //     placeholder: "Enter promcode ",
  //     defaultValue: "currntSelectedValue?.code",
  //     colClassName: "col-lg-4 col-md-6 col-12",
  //     type: "text",
  //     requiredData: {
  //       required: " required",
  //       maxLength: {
  //         value: 20,
  //         message: "max 20 character supported",
  //       },
  //     },
  //     currentAuthorization: "create",
  //   },
  //   {
  //     name: "Promo Name",
  //     formName: "name",
  //     placeholder: "Enter promcode name",
  //     defaultValue: "currntSelectedValue?.name",
  //     colClassName: "col-lg-4 col-md-6 col-12",
  //     type: "text",
  //     requiredData: {
  //       required: " required",
  //       maxLength: {
  //         value: 20,
  //         message: "max 20 character supported",
  //       },
  //     },
  //     currentAuthorization: "create",
  //   },
  //   // {
  //   //   name: "Number of Use Limit",
  //   //   formName: "displayOrder",
  //   //   placeholder: "Enter limit",
  //   //   defaultValue: currntSelectedValue?.displayOrder,
  //   //   type: "number",
  //   //   requiredData: {
  //   //     required: " required",
  //   //   },
  //   //   currentAuthorization: "create",
  //   // },
  //   {
  //     name: "Enable Now",
  //     formName: "isActive",
  //     placeholder: "Enter discount type",
  //     defaultValue: "No",
  //     dictInsideList: false,
  //     type: "dropdown1",
  //     dictInsideList: false,
  //     options: ["Yes", "No"],
  //     colClassName: "col-lg-4 col-md-6 col-12",
  //     currentAuthorization: "create",
  //   },
  //   {
  //     name: "Discount Percentage",
  //     formName: "percentageOrAmount",
  //     placeholder: "Enter discount percentage",
  //     defaultValue: 123,
  //     colClassName: "col-lg-4 col-md-6 col-12",
  //     type: "number",
  //     requiredData: {
  //       required: " required",
  //       min: {
  //         value: 1,
  //         message: "min discount is 1%",
  //       },
  //       max: {
  //         value: 100,
  //         message: "max discount is 100%",
  //       },
  //     },
  //     currentAuthorization: "create",
  //   },
  //   {
  //     name: "Start Date & Time",
  //     formName: "startDate",
  //     placeholder: "Enter start date time",
  //     defaultValue: new Date(),
  //     min: new Date()?.toISOString()?.slice(0, -8),
  //     colClassName: "col-lg-4 col-md-6 col-12",
  //     type: "datetime",
  //     requiredData: {
  //       required: " required",
  //     },
  //     currentAuthorization: "create",
  //   },
  //   {
  //     name: "End Date & Time",
  //     formName: "expireDate",
  //     placeholder: "End start date time",
  //     defaultValue: new Date(),
  //     colClassName: "col-lg-4 col-md-6 col-12",
  //     type: "datetime",
  //     requiredData: {
  //       required: " required",
  //     },
  //     currentAuthorization: "create",
  //   },
  // ]
  // const {
  //   register: register,
  //   formState: { errors },
  //   handleSubmit: handleSubmit,
  // } = useForm({})
  // const onSubmit = async data => {
  //   console.log(data)
  // }
  // return (
  //   <div>
  //     <Card>
  //       <CardBody className="p-2">
  //         <form onSubmit={handleSubmit(onSubmit)} id="custom-modal-form">
  //           <LabelNameToInputMapper
  //             errors={errors}
  //             register={register}
  //             labelNames={labelNames}
  //             btnName={btnName}
  //             setShowModal={setShowModal}
  //             setttingState={setFormReturnData}
  //             setCurrntAuthorization={setCurrentAuthorization}
  //           />
  //           <BtnNameToButtonMapper
  //             btnName={btnName}
  //             setShowModal={setShowModal}
  //             setttingState={setFormReturnData}
  //             setCurrntAuthorization={setCurrentAuthorization}
  //           />
  //         </form>
  //       </CardBody>
  //     </Card>
  //   </div>
  // )

  const [activeStep, setActiveStep] = React.useState(0)
  const [completed, setCompleted] = React.useState({})

  const totalSteps = () => {
    return stepComponents.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }
  const handleStep = stepIndex => () => {
    setActiveStep(stepIndex)
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has not been completed
          stepComponents.findIndex((_, index) => !(index in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleComplete = () => {
    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)
    handleNext()
  }

  const handleReset = () => {
    setActiveStep(0)
    setCompleted({})
  }
  const renderStepContent = stepIndex => {
    const StepComponent = stepComponents[stepIndex]?.component
    return StepComponent ? <StepComponent /> : <div>Not Found</div>
  }
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(data => console.log(data))}>
        <Box sx={{ width: "100%" }} className="p-6">
          <Stepper nonLinear activeStep={activeStep}>
            {stepComponents.map((stepComponent, index) => (
              <Step key={stepComponent.label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {stepComponent.label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          {renderStepContent(activeStep)}
          <div>
            {allStepsCompleted() ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {/* <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                  Step {activeStep + 1}
                </Typography> */}
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleNext} sx={{ mr: 1 }}>
                    Next
                  </Button>
                  {activeStep !== stepComponents.length - 1 &&
                    (completed[activeStep] ? (
                      <Typography
                        variant="caption"
                        sx={{ display: "inline-block" }}
                      >
                        Step {activeStep + 1} already completed
                      </Typography>
                    ) : (
                      <Button onClick={handleComplete}>
                        {completedSteps() === totalSteps() - 1
                          ? "Finish"
                          : "Complete Step"}
                      </Button>
                    ))}
                </Box>
              </React.Fragment>
            )}
          </div>
        </Box>
      </form>
    </FormProvider>
  )
}

export default connect(null, { setBreadcrumbItems })(ClientAdd)
