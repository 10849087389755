import { QueryClient, useMutation, useQueryClient } from "react-query"
import { useQuery } from "react-query"
import { toast } from "react-toastify"

import { api } from "../../api"
import { httpClient } from "../../http-helpers"

export const postPmsTransaction = body => {
  return httpClient.post(api.pmsService.pmsTransaction.postPmsTransaction, body)
}
export const putPmsTransaction = body => {
  return httpClient.put(api.pmsService.pmsTransaction.postPmsTransaction, body)
}
export const deletePmsTransaction = body => {
  return httpClient.delete(
    api.pmsService.pmsTransaction.deleteProtfolioOfUserByAdmin.replace(
      "{:txnId}",
      body.transactionId
    ),
    {
      params: {
        portfolioId: body.portfolioId,
      },
    }
  )
}
const getPortfolioBYId = userId => () => {
  return httpClient.get(api.pmsService.pmsTransaction.getProtfolioByAdminById, {
    params: { userId: userId },
  })
}
const getPortfolioBIdAndScrip = param => () => {
  return httpClient.get(api.pmsService.pmsTransaction.getProtfolioByAdminById, {
    params: { userId: param.userId, scrip: param.scrip },
  })
}

export const usePostPmsTransaction = body => {
  const queryClient = useQueryClient()
  return useMutation(
    api.pmsService.pmsTransaction.postPmsTransaction,
    postPmsTransaction,
    {
      onSuccess: (response, varibles) => {
        const { stockId, pmsUserId } = varibles
        queryClient.invalidateQueries([
          api.pmsService.pmsProtfolio.getAllProtfolioAdmin,
          stockId,
        ])
        queryClient.invalidateQueries([
          api.pmsService.pmsTransaction.getProtfolioByAdminById,
          pmsUserId,
        ])
        queryClient.invalidateQueries([
          api.pmsService.pmsReport.getSymmurayByAdmin,
          body.pmsUserId,
        ])
      },
      onError: error => {
        toast.error(error?.response?.data?.message)
      },
    }
  )
}
export const usePutPmsTransaction = body => {
  const queryClient = useQueryClient()
  return useMutation(
    api.pmsService.pmsTransaction.postPmsTransaction,
    putPmsTransaction,
    {
      onSuccess: (response, varibles) => {
        const { stockId, userId } = varibles
        queryClient.invalidateQueries([
          api.pmsService.pmsProtfolio.getAllProtfolioAdmin,
          stockId,
        ])
        queryClient.invalidateQueries([
          api.pmsService.pmsTransaction.getProtfolioByAdminById,
          userId,
        ])
        queryClient.invalidateQueries([
          api.pmsService.pmsReport.getSymmurayByAdmin,
          body.userId,
        ])
      },
      onError: error => {
        toast.error(error?.response?.data?.message)
      },
    }
  )
}
export const useDeletePmsTransaction = body => {
  const queryClient = useQueryClient()
  return useMutation(
    api.pmsService.pmsTransaction.deleteProtfolioOfUserByAdmin,
    deletePmsTransaction,
    {
      onSuccess: (response, varibles) => {
        const { stockId, userId } = varibles
        queryClient.invalidateQueries([
          api.pmsService.pmsProtfolio.getAllProtfolioAdmin,
          stockId,
        ])
        queryClient.invalidateQueries([
          api.pmsService.pmsTransaction.getProtfolioByAdminById,
          userId,
        ])
        queryClient.invalidateQueries([
          api.pmsService.pmsReport.getSymmurayByAdmin,
          body.userId,
        ])
      },
      onError: error => {
        toast.error(error?.response?.data?.message)
      },
    }
  )
}

export const useGetProtfolioById = userId => {
  return useQuery(
    [api.pmsService.pmsTransaction.getProtfolioByAdminById, userId],
    getPortfolioBYId(userId),
    {
      enabled: !!userId, // lazy query i.e. script na aako bela samma trigger hunna
      select: response => response.data,
      onError: error => {
        //   toastFail(error?.response?.data?.message || "Something Went Wrong");
      },
    }
  )
}

export const useGetProtfolioByIdAndScrip = param => {
  return useQuery(
    [
      api.pmsService.pmsTransaction.getProtfolioByAdminById,
      param.userId,
      param.scrip,
    ],
    getPortfolioBIdAndScrip(param),
    {
      enabled: !!param.scrip, // lazy query i.e. script na aako bela samma trigger hunna
      select: response => response.data,
      onError: error => {
        //   toastFail(error?.response?.data?.message || "Something Went Wrong");
      },
    }
  )
}
