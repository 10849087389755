import { useMutation, useQueryClient } from "react-query"
import { useQuery } from "react-query"

import { api } from "../../api"
import { httpClient } from "../../http-helpers"

const getNewsLetterByAdmin = () => () => {
  return httpClient.get(api.newsLetter.getAllNewsletterByAdmin)
}
const postUserMessage = body => {
  return httpClient.post(api.newsLetter.postNewsLetter, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export const useGetAllNewsLetter = () => {
  return useQuery(
    api.newsLetter.getAllNewsletterByAdmin,
    getNewsLetterByAdmin(),
    {
      select: response => response.data,
      onError: error => {
        //   toastFail(error?.response?.data?.message || "Something Went Wrong");
      },
    }
  )
}

export const usePostUserMessage = body => {
  const queryClient = useQueryClient()
  return useMutation(api.newsLetter.postNewsLetter, postUserMessage, {
    onSuccess: (response, variables) => {
      toast.success(response?.data?.message)
    },
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries([api.newsLetter.getAllNewsletterByAdmin])
    },

    onError: (error, variables) => {
      toast.error(error?.response?.data?.message)
    },
  })
}
