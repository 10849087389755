import React, { useEffect, useState } from "react"

import { Button, Card, CardBody, CardHeader, Modal, Row, Col } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { useAllStocksInNepse } from "services/fetchers/nepse/nepse"
import { useGetProtfolioByScript } from "services/fetchers/pmsservice/pmsProtfolio"

import CommonBasicTable from "components/UI/CommonBasicTable"
const Scrip = () => {
  const allNepseStockData = useAllStocksInNepse()

  const [showModal, setShowModal] = useState(false)

  const data = {
    columns: [
      {
        label: "Symbol",
        field: "symbol",
        sort: "asc",
        width: 100,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 350,
      },
      {
        label: "Diff",
        field: "diff",
        sort: "asc",
        width: 100,
      },

      {
        label: "Diff Per",
        field: "diff_per",
        sort: "asc",
        width: 100,
      },
      {
        label: "Prev Close",
        field: "prev_close",
        sort: "asc",
        width: 100,
      },
      {
        label: "Close",
        field: "close",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  }

  if (allNepseStockData?.data?.data?.length > 0) {
    const tempRows = allNepseStockData?.data?.data
    const temArray = []
    tempRows.forEach(element => {
      const tempDict = { ...element }
      const tempSymbol = (
        <div onClick={() => setShowModal(element.symbol)}>{element.symbol}</div>
      )
      tempDict.symbol = tempSymbol
      temArray.push(tempDict)
    })
    data.rows = temArray
  }
  const [dataModalTable, setDataModalTable] = useState({
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Script",
        field: "script",
        sort: "asc",
        width: 270,
      },
      {
        label: "Unit",
        field: "unit",
        sort: "asc",
        width: 200,
      },
      {
        label: "WACC",
        field: "wacc",
        sort: "asc",
        width: 100,
      },
      {
        label: "LTP",
        field: "ltp",
        sort: "asc",
        width: 150,
      },
      {
        label: "Realized Gain",
        field: "realizedGain",
        sort: "asc",
        width: 100,
      },
      {
        label: "Unrealized Gain",
        field: "unrealizedGain",
        sort: "asc",
        width: 100,
      },
      {
        label: "Net P/L",
        field: "netPL",
        sort: "asc",
        width: 100,
      },
      {
        label: "Commission",
        field: "commission",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  })

  const allocationOfSelectedScript = useGetProtfolioByScript(showModal)

  useEffect(() => {
    if (allocationOfSelectedScript?.data?.data) {
      if (allocationOfSelectedScript?.data?.data?.length > 0) {
        const tempArray = []
        allocationOfSelectedScript?.data?.data.forEach(element => {
          const tempFullName = element.json_agg[0].user["middle_name"]
            ? element.json_agg[0].user["first_name"] +
              " " +
              element.json_agg[0].user["middle_name"] +
              " " +
              element.json_agg[0].user["last_name"]
            : element.json_agg[0].user["first_name"] +
              " " +
              element.json_agg[0].user["last_name"]
          const ltpTemp =
            allNepseStockData?.data?.data[
              allNepseStockData?.data?.data.findIndex(
                x => x.symbol == element.stock_id
              )
            ]?.close
          const tempDict = {
            name: tempFullName,
            script: element.stock_id,

            unit: Number(element.quantity).toLocaleString(),
            wacc: Number(element.avg_cost).toLocaleString(),
            ltp: Number(ltpTemp).toLocaleString(),
            netPL: Number(element.net_profit).toLocaleString(),
            commission: Number(element.pms_commission).toLocaleString(),

            realizedGain: Number(element.realized_gain).toLocaleString(),
            unrealizedGain: Number(element.unrealized_gain).toLocaleString(),
          }
          tempArray.push(tempDict)
        })

        const newData = {
          ...dataModalTable,
          rows: tempArray,
        }

        setDataModalTable(newData)
      } else {
        const newData = {
          ...dataModalTable,
          rows: [],
        }

        setDataModalTable(newData)
      }
    }
  }, [allocationOfSelectedScript?.data])

  return (
    <div>
      {showModal && (
        <Modal
          size="lg"
          centered
          isOpen={showModal}
          style={{ width: "100%", maxWidth: "1200px", padding: "0% 10%" }}
        >
          <div className="modal-header">
            <h5 className="modal-title m-0 p-0" id="myLargeModalLabel">
              {{ showModal }}
            </h5>
            <button
              onClick={() => {
                setShowModal(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <MDBDataTable
              key={showModal}
              responsive
              striped
              noBottomColumns={true}
              data={dataModalTable}
            />
          </div>
        </Modal>
      )}
      <Card>
        <CardBody className="row">
          <CardHeader className="bg-primary text-white">Scrip</CardHeader>

          <div
            className="col-12 pt-4"
            id="data-table-id"
            style={{ minHeight: "520px" }}
          >
            <MDBDataTable
              responsive
              striped
              noBottomColumns={true}
              data={data}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
export default Scrip
