export const api = {
  auth: {
    rootUserLogin: "/user/root/auth",
  },
  nepse: {
    getNepseSummary: "/nepse/nepse-summary",
    getAllStocksInNepse: "nepse/stock",
  },
  pmsService: {
    pmsUser: {
      pmsUserCreateUser: "/pms-user",
      pmsUserGetAll: "/pms-user/root",
      getUserById: "/pms-user/root",
      putUserById: "/pms-user/root",
      getUserInvestment: "/pms-user/root/investment",
      postInvestment: "/pms-user/investment",
      deleteInvestment: "/pms-user/root/investment/${id}",
      postDocument: "/pms-user/root/document",
      getDocumentOfUserById: "/pms-user/root/document",
      postUserMessage: "/pms-user/root/message",
      deleteUserMessage: "pms-user/root/message/{id}",
    },
    pmsTransaction: {
      postPmsTransaction: "/pms-transaction",
      getProtfolioByAdminById: "/pms-transaction/root",
      deleteProtfolioOfUserByAdmin: "/pms-transaction/root/{:txnId}",
    },
    pmsReport: {
      getSymmurayByAdmin: "/pms-report/root",
    },
    pmsProtfolio: {
      getAllProtfolioAdmin: "/pms-portfolio/root",
      getProtfolioByUserId: "/pms-portfolio/root/{id}",
      getProtfolioGraph: "pms-report/root/graph?user=true&scrip=&sector=",
    },
  },
  newsLetter: {
    getAllNewsletterByAdmin: "/pms-newsletter/root",
    postNewsLetter: "/pms-newsletter",
  },
  kycForm: {
    getKycForm: "pms-user/root/kyc",
  },
}
