

import React, { useState } from "react"
import { Form, useForm } from "react-hook-form"

const PersonalInfo = () => {
  const [selectedImage, setSelectedImage] = useState(null)

  const handleImageChange = e => {
    const file = e.target.files[0]

    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setSelectedImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }
  const handleRemoveImage = () => {
    setSelectedImage(null)

    document.getElementById("imageInput").value = ""
  }
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
console.log(data)
  }

  return (
   <form  onSubmit={handleSubmit(onSubmit)}>
     <div className="mt-3">
      <div className="flex justify-between mb-3 gap-4">
        <div className="flex-col flex w-full">
          <label htmlFor="firstName" className="m-0">
            First Name
          </label>
          <input
            id="firstName"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("firstName", { required: "First Name is required" })}
          />
        </div>
        {errors.firstName && <span>{errors.firstName.message}</span>}
        <div className="flex-col flex w-full">
          <label htmlFor="firstName" className="m-0">
            Middle Name
          </label>
          <input
            id="firstName"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("firstName", { required: "First Name is required" })}
          />
        </div>
        {errors.firstName && <span>{errors.firstName.message}</span>}
        <div className="flex-col flex w-full">
          <label htmlFor="firstName" className="m-0">
            Last Name
          </label>
          <input
            id="firstName"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("firstName", { required: "First Name is required" })}
          />
        </div>
        {errors.firstName && <span>{errors.firstName.message}</span>}
      </div>
      <div className="flex justify-between gap-4">
        <div className="flex-col flex w-full">
          <label className="m-0" htmlFor="email">
            Email
          </label>
          <input
            id="email"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
        </div>
        {errors.email && <span>{errors.email.message}</span>}
        <div className="flex-col flex w-full">
          <label className="m-0" htmlFor="firstName">
            Phone Number
          </label>
          <input
            id="phoneNumber"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("phone", {
              required: "Number is Required",
              pattern: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            })}
          />
        </div>
        {errors.phoneNumber && <span>{errors.phoneNumber.message}</span>}
        <div className="flex-col flex w-full">
          <label className="m-0" htmlFor="agreementDate">
            Agreement Date
          </label>
          <input
            id="agreementDate"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("agreementDate", {
              required: "Agreement Date is required",
            })}
          />
        </div>
        {errors.agreementDate && <span>{errors.agreementDate.message}</span>}
      </div>

      <div className="flex-col flex w-full mt-3">
        <label className="">Profile Image </label>
        <div className=" w-[230px] rounded-md border cursor-pointer relative">
          <input
            id="imageInput"
            type="file"
            onChange={handleImageChange}
            className="  absolute inset-0 opacity-0 cursor-pointer"
          />
          {selectedImage ? (
            <div className="relative">
              <img
                src={selectedImage}
                alt="Selected Image"
                className="w-[230px] max-h-[200px] object-fit"
              />
              {/* <button
                onClick={handleRemoveImage}
                className="absolute top-0 right-0 text-black  text-xl font-semibold rounded-full cursor-pointer"
              >
                X
              </button> */}
              <>
                <div
                  className="absolute top-0  right-0 w-[25px] h-[25px] rounded-[25px] bg-[#575c61] flex items-center justify-center hover:opacity-90"
                  onClick={handleRemoveImage}
                >
                  <button className="text-[25px] flex text-white p-3">×</button>
                </div>
              </>
            </div>
          ) : (
            <span className="block p-4 text-center">Choose File</span>
          )}
        </div>
      </div>
    </div>
   </form>
  )
}

export default PersonalInfo
