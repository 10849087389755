import CommonBasicTable from "components/UI/CommonBasicTable"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Row, Col, Modal } from "reactstrap"

const Miniwidget = props => {
  const [showModal, setShowModal] = useState(false)
  const columnName = {
    fullName: "Full Name",
    mobile: "Mobile Number",
    email: "Email Address",
    commissionRate: "Comission Rate",
    investmentCorpus: "Investment Corpus",
    totalInvestment: "Total Investment",
    liquidFund: "Liquid Fund",
  }
  const clientData = [
    {
      fullName: (
        <Link to="/clients/name">
          <div className="text-dark">Aayush Neupanee</div>
        </Link>
      ),
      mobile: "9851346185",
      email: "aayushneupane922@gmail.com",
      commissionRate: 25,
      investmentCorpus: 25000,
      totalInvestment: 250,
      liquidFund: 20,
    },
    {
      fullName: (
        <Link to="/clients/name">
          <div className="text-dark">Aayush Neupanee</div>
        </Link>
      ),
      mobile: "1234567890",
      email: "johndoe@example.com",
      commissionRate: 20,
      investmentCorpus: 50000,
      totalInvestment: 500,
      liquidFund: 50,
    },
    {
      fullName: (
        <Link to="/clients/name">
          <div className="text-dark">Aayush Neupanee</div>
        </Link>
      ),
      mobile: "9851346185",
      email: "aayushneupane922@gmail.com",
      commissionRate: 25,
      investmentCorpus: 25000,
      totalInvestment: 250,
      liquidFund: 20,
    },
    {
      fullName: (
        <Link to="/clients/name">
          <div className="text-dark">Aayush Neupanee</div>
        </Link>
      ),
      mobile: "1234567890",
      email: "johndoe@example.com",
      commissionRate: 20,
      investmentCorpus: 50000,
      totalInvestment: 500,
      liquidFund: 50,
    },
    {
      fullName: (
        <Link to="/clients/name">
          <div className="text-dark">Aayush Neupanee</div>
        </Link>
      ),
      mobile: "9851346185",
      email: "aayushneupane922@gmail.com",
      commissionRate: 25,
      investmentCorpus: 25000,
      totalInvestment: 250,
      liquidFund: 20,
    },
    {
      fullName: (
        <Link to="/clients/name">
          <div className="text-dark">Aayush Neupanee</div>
        </Link>
      ),
      mobile: "1234567890",
      email: "johndoe@example.com",
      commissionRate: 20,
      investmentCorpus: 50000,
      totalInvestment: 500,
      liquidFund: 50,
    },
  ]
  return (
    <React.Fragment>
      {/* {showModal && (
        <Modal
          size="lg"
          centered
          isOpen={showModal}
          style={{ width: "100%", maxWidth: "1200px", padding: "0% 10%" }}
        >
          <div className="modal-header">
            <h5 className="modal-title m-0 p-0" id="myLargeModalLabel">
              Large Modal
            </h5>
            <button
              onClick={() => {
                setShowModal(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <CommonBasicTable data={clientData} columnParser={columnName} />
          </div>
        </Modal>
      )} */}
      <Row>
        {props.reports.map((report, key) => (
          <Col xl={3} sm={6} key={key}>
            <Card
              style={{ cursor: "pointer" }}
              className={
                Number(report.total) > 0
                  ? "bg-blue-primary mini-stat "
                  : report.total == 0
                  ? "bg-blue-primary mini-stat "
                  : "bg-blue-primary mini-stat "
              }
              onClick={() => setShowModal(true)}
            >
              <CardBody className="card-body mini-stat-img p-3">
                <div className="mini-stat-icon ">
                  <i className={"float-end ms-2 mdi" + report.iconClass}></i>
                </div>
                <div className="text-black">
                  <h4
                    className="text-uppercase  mb-2 font-size-12 text-black"
                    style={{
                      fontSize: "12px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {report.title}
                  </h4>
                  {report.isPercentage ? (
                    <h4
                      className="mb-1 text-black"
                      style={{
                        fontSize: "20px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {report.total + " %"}
                    </h4>
                  ) : (
                    <h4
                      className="mb-1 text-black"
                      style={{
                        fontSize: "20px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {Number(report.total).toLocaleString()}
                    </h4>
                  )}

                  {report.average && (
                    <>
                      {" "}
                      <h4
                        className={"badge bg-" + report.badgecolor}
                        style={{
                          fontSize: "12px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {" "}
                        {report.average}{" "}
                      </h4>{" "}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )
}

export default Miniwidget
