import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"

import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

// availity-reactstrap-validation
// import { AvForm, AvField } from "availity-reactstrap-validation"

import { useForm } from "react-hook-form"

// actions
import { loginUser, apiError } from "../../store/actions"

// import images
import logoLightPng from "../../assets/images/logo-light.png"
import logoDark from "../../assets/images/logo-dark.png"
import { useLogin } from "services/fetchers/auth/auth"

const Login = props => {
  // handleValidSubmit
  const {
    mutateAsync,
    error,
    mutate,
    isError,
    isLoading: isLoadingLogin,
  } = useLogin()
  const navigate = useNavigate()

  const {
    register: register,
    formState: { errors },
    handleSubmit: handleSubmit,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const onSubmit = async values => {
    const responseData = await mutateAsync(values)
    console.log(responseData)
    if (responseData?.data?.data?.token) {
      localStorage.setItem("token", responseData?.data?.data?.token)
      const domain = window.location.origin
      const newUrl = domain + "/dashboard"
      window.location.replace(newUrl)
      props.loginUser(values, props.history)
    } else {
      props.loginUser(values, props.history)
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src={logoDark}
                        alt=""
                        height="30"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logoLightPng}
                        alt=""
                        height="30"
                        className="auth-logo-light"
                      />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className=" font-size-18 mb-1 text-center text-white">
                      Welcome Back !
                    </h4>
                    <p className="text-white text-center">
                      Sign in to continue .
                    </p>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      id="custom-modal-form"
                    >
                      <div className="mb-3">
                      <label className=" col-form-label  fw-bold fs-6">
                        Email
                      </label>
                        <input
                          type="email"
                          style={{
                            border: errors?.email ? "1px solid red" : "",
                          }}
                          {...register("email", {
                            required: " required",
                          })}
                          name="email"
                          className="form-control form-control form-control-solid"
                        />
                      </div>
                      <div className="mb-3">
                      <label className=" col-form-label  fw-bold fs-6">
                        Password
                      </label>
                        <input
                          type="password"
                          style={{
                            border: errors?.password ? "1px solid red" : "",
                          }}
                          {...register("password", {
                            required: " required",
                          })}
                          name="password"
                          className="form-control form-control form-control-solid"
                        />
                      </div>

                      <div className="mb-3 row mt-4">
                        <div className="col-2"></div>
                        <div className="col-6 text-end">
                          <button
                            disabled={isLoadingLogin}
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            {isLoadingLogin ? (
                              <Spinner size="sm" color="light"></Spinner>
                            ) : (
                              "Log In"
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="form-group mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/forgot-password" className=" text-white">
                            <i className="mdi mdi-lock"></i> Forgot your
                            password?
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
              {/* <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="register" className="text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  <i className="mdi mdi-heart text-danger"></i>
                </p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default connect(mapStateToProps, { loginUser, apiError })(Login)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
