import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

//  // Inner Authentication


// Dashboard
import Dashboard from "../pages/Dashboard/index"

import Pages404 from "../pages/Extra Pages/pages-404"
import Pages500 from "../pages/Extra Pages/pages-500"

// Clients
import ClientList from "components/components/clients/ClientList"
import ClientAdd from "components/components/clients/ClientAdd"
import AllocationSearch from "components/components/allocations/AllocationSearch"
import ReportSearch from "components/components/reports/ReportSearch"
import AddHoldings from "components/components/holdings/AddHoldings"
import FileUploadExample from "components/components/holdings/FileUploadExample"
import ClientReportDetail from "components/components/reports/ClientReportDetail"
import Settings from "components/components/clients/Settings"
import Scrip from "components/components/scrip/Scrip"
import Newsletter from "components/components/newsletters/Newsletter"

const userRoutes = [
  { path: "/", component: Dashboard },
  { path: "/dashboard", component: Dashboard },

  //newsletter

  // Guests Pages
  {
    path: "/clients",
    component: ClientList,
  },
  {
    path: "/clients/:id",
    component: ClientReportDetail,
  },
  {
    path: "/clients/settings/:id",
    component: Settings,
  },
  //Allocation pages
  {
    path: "/allocations",
    component: AllocationSearch,
  },
  // Search Report pages
  {
    path: "/report-search",
    component: ReportSearch,
  },
  //  holdings pages
  { path: "/add-holdings", component: AddHoldings },
  { path: "/file-upload", component: FileUploadExample },
  //scrip
  { path: "/scrip", component: Scrip },
  { path: "/newsletter", component: Newsletter },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },

  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },



]

export { userRoutes, authRoutes }
