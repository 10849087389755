import React from "react"

function CommonBasicTable({ data, columnParser }) {
  const columns = Object.keys(data[0])

  return (
    <div className="table-responsive">
      <table className="table table-hover mb-0">
        <thead>
          <tr>
            {columns.map((column, index) => {
              if (columnParser[column]) {
                return (
                  <th
                    key={index}
                    className="text-capitalize text-white bg-primary"
                  >
                    {columnParser[column] || columnParser[column]}
                  </th>
                )
              }
              return null
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((client, rowIndex) => (
            <tr
              key={rowIndex}
              style={{
                backgroundColor:
                  rowIndex % 2 === 0 ? "#fff !important" : "#f2f2f2 !important",
              }}
            >
              {columns.map((column, colIndex) => {
                if (columnParser[column]) {
                  return (
                    <td key={colIndex}>
                      {typeof client[column] === "string" &&
                      client[column].startsWith("<") ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: client[column] }}
                        />
                      ) : (
                        client[column]
                      )}
                    </td>
                  )
                }
                return null
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CommonBasicTable
