import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import {
  Row,
  Col,
  Table,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardText,
  Spinner,
} from "reactstrap"

// Pages Components
import Miniwidget from "./Miniwidget"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import CommonBasicTable from "components/UI/CommonBasicTable"
import { Link } from "react-router-dom"

// for skeleton ui
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

// importing the required api hooks
import { useGetNepseSummary } from "services/fetchers/nepse/nepse"
import { useGetAllUserList } from "services/fetchers/pmsservice/pmsuser"
import { useGetDashboardSummery } from "services/fetchers/pmsservice/pmsReport"
import Miniwidget1 from "./Miniwidget1"
import { MDBDataTable } from "mdbreact"

const Dashboard = props => {
  const breadcrumbItems = [
    { title: "PMS", link: "#" },
    { title: "Dashboard", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Dashboard", breadcrumbItems)
  })
  const getAllNepseSummary = useGetNepseSummary()

  console.log(getAllNepseSummary)
  const [dashboardSummery, setDashboardSummery] = useState([])
  const [nepseIndices, setNepseIndices] = useState([])
  const [nepseSubIndices, setNepseSubIndices] = useState([])

  useEffect(() => {
    if (getAllNepseSummary?.data?.data) {
      if (
        getAllNepseSummary?.data?.data[0]?.nepse_indices?.nepse_indices
          ?.length > 0
      ) {
        const tempArray = []

        getAllNepseSummary?.data?.data[0]?.nepse_indices?.nepse_indices.forEach(
          (element, index) => {
            const tempDict = {}
            if (element?.symbol?.toUpperCase() === "NEPSE") {
              tempDict.title = element.symbol
              tempDict.iconClass = "cube-outline"
              tempDict.total = element.ltp
              tempDict.average = element.percent_change
              tempDict.badgecolor =
                element.percent_change > 0 ? "success" : "danger"
              tempArray.push(tempDict)
            }
          },
        )
        setNepseIndices(() => tempArray)
      }
      if (
        getAllNepseSummary?.data?.data[0]?.nepse_sub_indices?.nepse_sub_indices
          ?.length > 0
      ) {
        const tempArray = []

        getAllNepseSummary?.data?.data[0]?.nepse_sub_indices?.nepse_sub_indices.forEach(
          (element, index) => {
            const tempDict = {}
            tempDict.title = element.symbol
            tempDict.iconClass = ""
            tempDict.total = element.ltp
            tempDict.average = element.percent_change
            tempDict.badgecolor =
              element.percent_change > 0 ? "success" : "danger"
            tempArray.push(tempDict)
          },
        )
        console.log("setting statesss")
        setNepseSubIndices(() => tempArray)
      }
    }
  }, [getAllNepseSummary?.data?.data])

  // data table for user lists
  const data = {
    columns: [
      {
        label: "Full Name",
        field: "full_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Phone Number",
        field: "phone",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },

      {
        label: "Agreement Date",
        field: "agreement_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Commission Rate",
        field: "commission_rate",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  }

  const getAllUserList = useGetAllUserList()

  if (getAllUserList?.data?.data?.length > 0) {
    const tempArray = []
    getAllUserList?.data?.data.forEach((element, index) => {
      const tempDict = {}
      const tempFullName = (
        <div>
          <Link to={`/clients/settings/${element["user_id"]}`}>
            {element["middle_name"]
              ? element["first_name"] +
                " " +
                element["middle_name"] +
                " " +
                element["last_name"]
              : element["first_name"] + " " + element["last_name"]}
          </Link>
        </div>
      )
      const tempActions = (
        <div>
          <div className="d-flex justify-content-between">
            <div
              onClick={() => {
                setShowModal(true)
                setCurrntSelectedValue(() => element)
                setCurrentAuthorization("update")
              }}
            >
              Edit
            </div>
            <div
              onClick={() => {
                setShowModal(true)
                setCurrntSelectedValue(() => element)
                setCurrentAuthorization("read")
              }}
            >
              View
            </div>
          </div>
        </div>
      )
      tempDict["full_name"] = tempFullName
      tempDict["agreement_date"] = element["agreement_date"]
      tempDict["phone"] = element["phone"]
      tempDict["email"] = element["email"]
      tempDict["commission_rate"] = element["commission_rate"]

      tempArray.push(tempDict)
    })
    data.rows = tempArray
  }

  const dashboardSummeryData = useGetDashboardSummery()
  useEffect(() => {
    if (dashboardSummeryData?.data?.data) {
      const reports = [
        {
          title: "Total Clients",
          iconClass: "mdi-human-male-male",
          total: getAllUserList?.data?.data?.length,
          // average: "+11%",
          badgecolor: "info",
        },
        {
          title: "UNREALIZED COMMISION EARNED",
          iconClass: "mdi-cash-lock",
          total: Number(
            dashboardSummeryData?.data?.data["pms_commission"],
          ).toFixed(2),
          // average: "-29%",
          badgecolor: "danger",
        },
        {
          title: "REALIZED COMMISION EARNED",
          iconClass: "mdi-cash-lock-open",
          total: Number(
            dashboardSummeryData?.data?.data["total_realized_commission"],
          ).toFixed(2),
          // average: "0%",
          badgecolor: "warning",
        },
        {
          title: "UNREALIZED GAIN/LOSS",
          iconClass: "mdi-plus-minus-variant",
          total: Number(
            dashboardSummeryData?.data?.data["total_unrealized_gain"],
          ).toFixed(2),
          // average: "+89%",
          badgecolor: "info",
        },
        {
          title: "REALIZED GAIN/LOSS",
          iconClass: "mdi-cash",
          total: Number(
            dashboardSummeryData?.data?.data["total_realized_gain"],
          ).toFixed(2),
          // average: "0%",
          badgecolor: "warning",
        },
        {
          title: "Total Corpus",
          iconClass: "mdi-account-cash",
          total: Number(
            dashboardSummeryData?.data?.data["total_corpus"],
          ).toFixed(2),
          // average: "-29%",
          badgecolor: "danger",
        },
        {
          title: "Total Investment",
          iconClass: "mdi-cash-plus",
          total: Number(
            dashboardSummeryData?.data?.data["total_investment"],
          ).toFixed(2),
          // average: "-29%",
          badgecolor: "danger",
        },
        {
          title: "Total Liquid Fund",
          iconClass: "mdi-cash-check",
          total: Number(
            dashboardSummeryData?.data?.data["total_liquid_fund"],
          ).toFixed(2),
          // average: "-29%",
          badgecolor: "danger",
        },
      ]
      setDashboardSummery(() => reports)
    }
  }, [dashboardSummeryData?.data?.data, getAllUserList?.data?.data])

  return (
    <React.Fragment>
      <MetaTags>
        <title>PMIS</title>
      </MetaTags>
      <Card>
        {getAllNepseSummary?.isLoading && nepseIndices?.length === 0 && (
          <Row>
            {[1, 2, 3, 4].map(item => {
              return (
                <Col xl={3} sm={6} className="p-2">
                  <SkeletonTheme baseColor="#0b5947">
                    <Skeleton height={100} />
                  </SkeletonTheme>
                </Col>
              )
            })}
          </Row>
        )}
        {getAllNepseSummary?.isError && (
          <div className="p-2">
            <div className="text-danger text-center align-center">
              Something went wrong while showing nepse summary
            </div>
          </div>
        )}
        <div style={{}} className="simplebar-content-wrapper">
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              scrollbarWidth: "thin",
              scrollbarWidth: "none",
            }}
          >
            {nepseIndices?.length > 0 && <Miniwidget1 reports={nepseIndices} />}

            {nepseSubIndices?.length > 0 && (
              <Miniwidget1 reports={nepseSubIndices} />
            )}
          </div>
        </div>
      </Card>

      {dashboardSummery?.length > 0 && (
        <Miniwidget reports={dashboardSummery} />
      )}
      {dashboardSummeryData?.isLoading && dashboardSummery?.length === 0 && (
        <Row>
          {[...Array(8)].map(item => {
            return (
              <Col xl={3} sm={6} className="p-2">
                <SkeletonTheme baseColor="#0b5947">
                  <Skeleton height={100} />
                </SkeletonTheme>
              </Col>
            )
          })}
        </Row>
      )}

      <Row>
        <Col xl="12">
          {/* latest transactions */}
          <Card>
            <CardBody>
              <CardTitle className=" ">
                <h5>Clients</h5>{" "}
              </CardTitle>
              <p className="card-title-desc"></p>

              <div
                className=""
                id="data-table-id"
                style={{ minHeight: "520px" }}
              >
                <MDBDataTable
                  responsive
                  striped
                  noBottomColumns={true}
                  data={data}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row></Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard)
