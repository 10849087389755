import React from "react"

import { Button } from "reactstrap"
import FormErrorMessage from "./FormErrorMessage"

function LabelNameToInputMapper(props) {
  return (
    <div className="row">
      {props.labelNames.map((labelName, i) => (
        <div className={labelName.colClassName} key={labelName.defaultValue}>
          <label className=" col-form-label  fw-bold fs-6">
            {labelName.name}
          </label>
          {labelName.requiredData && labelName.requiredData.required && (
            <sup style={{ color: "red" }} className=" mdi mdi-star"></sup>
          )}
          {(labelName.type == "text" || labelName.type == "number") && (
            <input
              readOnly={
                labelName.currentAuthorization == "read" ||
                labelName.currentAuthorization == "delete"
              }
              type={labelName.type}
              step={labelName.step && labelName.step}
              defaultValue={labelName.defaultValue}
              className="form-control form-control form-control-solid"
              placeholder={labelName.placeholder}
              style={{
                border: props.errors[labelName.formName] ? "1px solid red" : "",
                textTransform: labelName.isCapitalize ? "capitalize" : "",
              }}
              {...props.register(labelName.formName, labelName.requiredData)}
            />
          )}

          {labelName.type == "dropdown" && (
            <select
              disabled={
                labelName.currentAuthorization == "read" ||
                labelName.currentAuthorization == "delete"
              }
              defaultValue={JSON.stringify(labelName.defaultValue)}
              className="form-select form-select-solid form-select"
              // style={{
              //   border: props.errors.hotelType ? '1px solid red' : '',
              // }}
              style={{
                border: props.errors[labelName.formName] ? "1px solid red" : "",
              }}
              {...props.register(labelName.formName, labelName.requiredData)}
              onChange={e => {
                console.log(JSON.parse(e.target.value))
                labelName.shouldHandleChange &&
                  labelName.handleSettingState(() => JSON.parse(e.target.value))
              }}
            >
              {labelName.dictInsideList &&
                labelName.options &&
                labelName.options.map((option, i) => {
                  // console.log(labelName.defaultValue, option[labelName.displayKey])
                  return (
                    <option
                      value={JSON.stringify(option)}
                      // value={i}
                      // this part was updated to above value to faciliate roster changes

                      key={option[labelName.displayKey]}
                      selected={
                        labelName.defaultValue == option[labelName.displayKey]
                      }
                    >
                      {labelName.displayKey == "firstName"
                        ? option[labelName.displayKey] +
                          " " +
                          option["lastName"]
                        : option[labelName.displayKey]}
                    </option>
                  )
                })}

              {!labelName.dictInsideList &&
                labelName.options &&
                labelName.options.map((option, i) => {
                  // console.log(labelName.defaultValue, option)
                  return (
                    <option
                      value={option}
                      selected={
                        labelName.defaultValue &&
                        labelName.defaultValue == option
                      }
                    >
                      {option}
                    </option>
                  )
                })}
            </select>
          )}
          {labelName.type == "dropdown1" && (
            <select
              disabled={
                labelName.currentAuthorization == "read" ||
                labelName.currentAuthorization == "delete"
              }
              defaultValue={labelName.defaultValue}
              className="form-select form-select-solid form-select"
              // style={{
              //   border: props.errors.hotelType ? '1px solid red' : '',
              // }}
              style={{
                border: props.errors[labelName.formName] ? "1px solid red" : "",
              }}
              {...props.register(labelName.formName, labelName.requiredData)}
              onChange={e => {
                labelName.shouldHandleChange &&
                  labelName.handleSettingState(() => JSON.parse(e.target.value))
              }}
            >
              {labelName.dictInsideList &&
                labelName.options &&
                labelName.options.map((option, i) => {
                  // console.log(labelName.defaultValue, option[labelName.displayKey])
                  return (
                    <option
                      value={JSON.stringify(option)}
                      // value={i}
                      // this part was updated to above value to faciliate roster changes

                      key={option[labelName.displayKey]}
                      selected={
                        labelName.defaultValue == option[labelName.displayKey]
                      }
                    >
                      {labelName.displayKey == "firstName"
                        ? option[labelName.displayKey] +
                          " " +
                          option["lastName"]
                        : option[labelName.displayKey]}
                    </option>
                  )
                })}

              {!labelName.dictInsideList &&
                labelName.options &&
                labelName.options.map((option, i) => {
                  // console.log(labelName.defaultValue, option)
                  return (
                    <option
                      value={option}
                      selected={
                        labelName.defaultValue &&
                        labelName.defaultValue == option
                      }
                    >
                      {option}
                    </option>
                  )
                })}
            </select>
          )}
          {/* {labelName.type == "select" && labelName.dictInsideList && (
            <CreatableSelect
              isClearable={true}
              isdisabled={
                labelName.currentAuthorization == "read" ||
                labelName.currentAuthorization == "delete" ||
                props.isLoading
              }
              onCreateOption={props.handleCreate}
              isLoading={props.isLoading}
              style={{
                border: props.errors[labelName.formName] ? "1px solid red" : "",
              }}
              value={props.currentValue}
              {...props.register(labelName.formName, labelName.requiredData)}
              onChange={e => selectHandleChange(e)}
              options={[...props.roomServicesInBranch]}
            ></CreatableSelect>
          )}

          {labelName.type == "select" && !labelName.dictInsideList && (
            <CreatableSelect
              isdisabled={
                labelName.currentAuthorization == "read" ||
                labelName.currentAuthorization == "delete"
              }
              isLoading={props.isLoading}
              className=""
              style={{
                border: props.errors[labelName.formName] ? "1px solid red" : "",
              }}
              {...props.register(labelName.formName, labelName.requiredData)}
              // options={labelName.options.map((option) => {
              //   return option[labelName.displayKey]
              // })}
              options={labelName.options}
            ></CreatableSelect>
          )} */}

          {labelName.type == "date" && (
            <input
              defaultValue={labelName.defaultValue}
              type="date"
              max={
                labelName.isNoMax
                  ? ""
                  : new Date()?.toISOString()?.split("T")[0]
              }
              className="form-control form-control form-control-solid"
              style={{
                border: props.errors[labelName.formName] ? "1px solid red" : "",
              }}
              {...props.register(labelName.formName, labelName.requiredData)}
            />
          )}

          {labelName.type == "datetime" && (
            <input
              min={labelName.min}
              defaultValue={labelName.defaultValue}
              type="datetime-local"
              className="form-control form-control form-control-solid"
              style={{
                border: props.errors[labelName.formName] ? "1px solid red" : "",
              }}
              {...props.register(labelName.formName, labelName.requiredData)}
            />
          )}

          {labelName.type == "email" && (
            <input
              defaultValue={labelName.defaultValue}
              type={labelName.type}
              step={labelName.step && labelName.step}
              className="form-control form-control form-control-solid"
              placeholder={labelName.placeholder}
              style={{
                border: props.errors[labelName.formName] ? "1px solid red" : "",
              }}
              readOnly={
                labelName.currentAuthorization == "read" ||
                labelName.currentAuthorization == "delete"
              }
              {...props.register(labelName.formName, labelName.requiredData)}
            />
          )}
          {labelName.type == "time" && (
            <input
              defaultValue={labelName.defaultValue}
              type="time"
              className="form-control form-control form-control-solid"
              style={{
                border: props.errors[labelName.formName] ? "1px solid red" : "",
              }}
              {...props.register(labelName.formName, labelName.requiredData)}
            />
          )}
          {labelName.type == "textarea" && (
            <textarea
              readOnly={
                labelName.currentAuthorization == "read" ||
                labelName.currentAuthorization == "delete"
              }
              defaultValue={labelName.defaultValue}
              className="form-control form-control form-control-solid "
              style={{
                border: props.errors[labelName.formName] ? "1px solid red" : "",
              }}
              {...props.register(labelName.formName, labelName.requiredData)}
            ></textarea>
          )}
          {props.errors[labelName.formName] && (
            <FormErrorMessage
              errorMessage={props.errors[labelName.formName].message}
            ></FormErrorMessage>
          )}
        </div>
      ))}
    </div>
  )
}

export default LabelNameToInputMapper
