import React from "react"

import ReactECharts from "echarts-for-react"

const PieChart = () => {
  const data = [
    { name: "Category 1", value: 30 },
    { name: "Category 2", value: 45 },
    { name: "Category 3", value: 25 },
  ]

  // Options for the pie chart
  const options = {
    tooltip: {
      trigger: "item",
      color: "#fff",
    },
    legend: {
      top: "5%",
      left: "center",
      className: "text-white",
      color: "#fff",
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        className: "text-white",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
          color: "#fff",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 24,
            fontWeight: "bold",
            color: "#fff",
          },
        },
        labelLine: {
          show: false,
        },
        data: data,
      },
    ],
  }

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center gap-4"
      style={{ maxHeight: "500px", marginTop: "80px" }}
    >
      <h2 className="font-size-16 text-white">Total Portfolio Value</h2>
      <ReactECharts
        className="echarts-for-echarts text-white"
        option={options}
        style={{
          width: "100%",
          height: "400px",
        }}
      />
      {/* <Pie width={474} height={260} data={data} /> */}
    </div>
  )
}

export default PieChart
