import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"

const CommonCard = props => {
  return (
    <div className="bg-fill border mb-3 py-4 px-3 rounded-lg mr-6">
      <div className="flex gap-2 hover:cursor-pointer">
        <p>{props.image}</p>
        <p className="text-title text-[14px]">{props.title}</p>
      </div>
      <p className="text-count text-[22px] mt-2 font-semibold ">
        {props.count}
      </p>
      <div className="flex justify-between items-center mt-2">
        <p className="text-red-700">14.68%</p>
        <p>This month: $120,280</p>
      </div>
      <div className="border my-3"></div>
      <button className="flex items-center gap-2">
        <p>View Report</p>

        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </div>
  )
}

export default CommonCard
