import React, { useEffect, useState } from "react"

import { Row, Col, Card, Form, CardBody, CardTitle } from "reactstrap"
import { useDropzone } from "react-dropzone"

const FileUploadExample = props => {
  const [fileName, setFileName] = useState("")

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      maxFiles: 1,
    })

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map(e => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    )
  })

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4 bg-primary">Upload File</CardTitle>
              <Row>
                <Col xl={2} sm={6}>
                  <label className="text-white col-form-label  fw-bold fs-6">
                    File Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-control form-control-solid"
                    onChange={e => {
                      setFileName(() => e.target.value)
                    }}
                  />
                </Col>
                <Col xl={10} sm={6}>
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                    <em>(only 1 file is accepted)</em>
                  </div>
                  {/* <aside>
                    <h4>Accepted files</h4>
                    
                    <h4>Rejected files</h4>
                    <ul>{fileRejectionItems}</ul>
                  </aside> */}
                  <aside>
                    <ul>{acceptedFileItems}</ul>
                  </aside>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FileUploadExample
