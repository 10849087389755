import React, { useState } from 'react';

export default function Documents() {
  const [images, setImages] = useState([]);

  const handleFilesChange = (e) => {
    const files = Array.from(e.target.files);
    const filesWithPreview = files.map(file => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = (e) => {
          resolve({ src: e.target.result, caption: '' });
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(filesWithPreview).then(files => {
      setImages(prevImages => [...prevImages, ...files]);
    });
  };

  const handleCaptionChange = (index, caption) => {
    const newImages = [...images];
    newImages[index].caption = caption;
    setImages(newImages);
  };

  const removeImage = (index) => {
    setImages(images.filter((_, idx) => idx !== index));
  };

  return (
    <div className="my-4 mx-2">
      <h1 className="text-xl font-semibold mb-2">Upload Documents</h1>
  
      <input
        className="border p-2 my-2 file:border-0 file:px-4 file:py-2 file:rounded file:bg-blue-500 file:text-white cursor-pointer"
        type="file"
        multiple
        onChange={handleFilesChange}
      />
      <div className="grid grid-cols-3 gap-4 mt-4">
        {images.map((image, index) => (
          <>
          <div key={index} className="w-full relative">
            <div className='bg-[#D1D5DB] w-6 h-6 flex items-center rounded-[50%]  absolute -right-1  -top-2'>
            <button
              onClick={() => removeImage(index)}
              className=" bg-red-500 text-black px-[5px] rounded-full flex text-center text-[25px]"
              aria-label="Remove image"
            >
              &times;
            </button>
            </div>
          
            <img src={image.src} alt={`Upload ${index}`} className="w-full h-[130px]" />
            <input
              type="text"
              value={image.caption}
              onChange={(e) => handleCaptionChange(index, e.target.value)}
              placeholder="Enter caption"
              className="mt-1 px-1 py-2  w-full border-2 border-slate-300 rounded-md"
            />
          </div></>
        ))}
      </div>
    </div>
  );
}
