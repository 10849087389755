import React from "react"
import PropTypes from "prop-types"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Layouts
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// Import scss
import "./assets/scss/theme.scss"

const App = props => {
  function getLayout() {
    switch (props.layout.layoutType) {
      case "horizontal":
        return HorizontalLayout
      default:
        return VerticalLayout
    }
  }

  const Layout = getLayout()

  return (
    <React.Fragment>
      <Routes>
        {authRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <Authmiddleware
                component={route.component}
                layout={NonAuthLayout}
                isAuthProtected={false}
              />
            }
          />
        ))}

        {userRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <Authmiddleware
                component={route.component}
                layout={Layout}
                isAuthProtected={true}
              />
            }
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
