// personalInfo.js

import React from "react"
import { useForm} from "react-hook-form"
import DatePicker from "./inputs/DatePicker"

const InvestmentInfo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();


  const onSubmit = (data) =>{
    console.log(data)
  }
  

  return (
   <form onSubmit={handleSubmit(onSubmit)}>
     <div className="mt-3">
      <div className="flex justify-between mb-3 gap-4">
        <div className="flex-col flex w-full">
          <label htmlFor="firstName" className="m-0">
            Commission Rate
          </label>
          <input
            id="firstName"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("firstName", { required: "First Name is required" })}
          />
        </div>
        {errors.firstName && <span>{errors.firstName.message}</span>}
        <div className="flex-col flex w-full">
          <label htmlFor="firstName" className="m-0">
            Leverage Amount
          </label>
          <input
            id="firstName"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("firstName", { required: "First Name is required" })}
          />
        </div>
        {errors.firstName && <span>{errors.firstName.message}</span>}
        <div className="flex-col flex w-full">
          <label htmlFor="firstName" className="m-0">
            Commitment Amount
          </label>
          <input
            id="firstName"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("firstName", { required: "First Name is required" })}
          />
        </div>
        {errors.firstName && <span>{errors.firstName.message}</span>}
      </div>
      <div className="flex justify-between gap-4">
        <div className="flex-col flex w-full">
          <label className="m-0" htmlFor="email">
            Maturity Date
          </label>
          <DatePicker />
        </div>
        {errors.email && <span>{errors.email.message}</span>}
        <div className="flex-col flex w-full">
          <label className="m-0" htmlFor="firstName">
            AMF Type
          </label>
          <input
            id="phoneNumber"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("phoneNumber", {
              required: "Phone Number is required",
            })}
          />
        </div>
        {errors.phoneNumber && <span>{errors.phoneNumber.message}</span>}
        <div className="flex-col flex w-full">
          <label className="m-0" htmlFor="agreementDate">
            AMF Value
          </label>
          <input
            id="agreementDate"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("agreementDate", {
              required: "Agreement Date is required",
            })}
          />
        </div>
        {errors.agreementDate && <span>{errors.agreementDate.message}</span>}
      </div>
      <div className="flex justify-between gap-4">
        <div className="flex-col flex w-full">
          <label className="m-0" htmlFor="refereeName">
            Referee Name
          </label>
          <input
            id="refereeName"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("refereeName", {
              required: "Referee Name is required",
            })}
          />
        </div>
        {errors.refereeName && <span>{errors.refereeName.message}</span>}
        <div className="flex-col flex w-full">
          <label className="m-0" htmlFor="refereeEmail">
          Referee Email
          </label>
          <input
            id="refereeEmail"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("refereeEmail", {
              required: "Referee Email is required",
            })}
          />
        </div>
        {errors.refereeEmail && <span>{errors.refereeEmail.message}</span>}
        <div className="flex-col flex w-full">
          <label className="m-0" htmlFor="refereeCommission">
          Referee Commission
          </label>
          <input
            id="refereeCommission"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("refereeCommission", {
              required: " Referee Commission is required",
            })}
          />
        </div>
        {errors.refereeCommission && <span>{errors.refereeCommission.message}</span>}
      </div>
    </div>
   </form>
  )
}

export default InvestmentInfo
