import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useForm, Controller } from "react-hook-form"
import { setBreadcrumbItems } from "../../../store/actions"
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import FormErrorMessage from "components/Common/FormErrorMessage"
import Select from "react-select"
import moment from "moment"
import { useGetAllUserList } from "services/fetchers/pmsservice/pmsuser"

function ReportSearch(props) {
  const breadcrumbItems = [
    { title: "Home", link: "/" },
    { title: "Report Search", link: "/report-search" },
  ]
  useEffect(() => {
    props.setBreadcrumbItems("Modals", breadcrumbItems)
  }, [])

  const allUserList = useGetAllUserList()

  const [selectedOption, setSelectedOption] = useState("")
  const [startDateFrom, setStartDateFrom] = useState("")
  const [endDateFrom, setEndDateFrom] = useState("")
  const [userDropDownList, setUserDropDownList] = useState([])

  useEffect(() => {
    if (allUserList?.data?.data?.length > 0) {
      const tempArray = []
      allUserList?.data?.data.forEach(element => {
        const tempDict = {}
        const fullName = element["middle_name"]
          ? element["first_name"] +
            " " +
            element["middle_name"] +
            " " +
            element["last_name"]
          : element["first_name"] + " " + element["last_name"]
        tempDict.label = fullName
        tempDict.value = element["user_id"]
        tempArray.push(tempDict)
      })
      setUserDropDownList(() => tempArray)
    }
  }, [allUserList?.data?.data])

  const tableData = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Script",
        field: "script",
        sort: "asc",
        width: 270,
      },
      {
        label: "Unit",
        field: "unit",
        sort: "asc",
        width: 200,
      },
      {
        label: "WACC",
        field: "wacc",
        sort: "asc",
        width: 100,
      },
      {
        label: "LTP",
        field: "ltp",
        sort: "asc",
        width: 150,
      },
      {
        label: "Realized Gain",
        field: "realizedGain",
        sort: "asc",
        width: 100,
      },
      {
        label: "Unrealized Gain",
        field: "unrealizedGain",
        sort: "asc",
        width: 100,
      },
      {
        label: "Net P/L",
        field: "netPL",
        sort: "asc",
        width: 100,
      },
      {
        label: "Commission",
        field: "commission",
        sort: "asc",
        width: 100,
      },
      {
        label: "Details",
        field: "details",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  }
  const {
    register: register,
    formState: { errors },
    control: control,
    watch: watch,
    handleSubmit: handleSubmit,
  } = useForm({})

  const onSubmit = async data => {
    console.log(data)

    console.log(selectedOption, startDateFrom, endDateFrom)
    tableData.rows[0] = {
      name: "Tiger Nixon",
      script: "SHIVM",

      unit: Number(Math.random() * 100).toFixed(0),
      wacc: Number(Math.random() * 100).toFixed(2),
      ltp: Number(Math.random() * 100).toFixed(2),
      netPL: Number(Math.random() * 100).toFixed(2),
      commission: Number(Math.random() * 100).toFixed(2),
      details: "2011/04/25",
      realizedGain: Number(Math.random() * 10000).toFixed(2),
      unrealizedGain: Number(Math.random() * 10000).toFixed(2),
    }
    tableData.rows[1] = {
      name: "Tiger Nixon",
      script: "SHIVM",

      unit: Number(Math.random() * 100).toFixed(0),
      wacc: Number(Math.random() * 100).toFixed(2),
      ltp: Number(Math.random() * 100).toFixed(2),
      netPL: Number(Math.random() * 100).toFixed(2),
      commission: Number(Math.random() * 100).toFixed(2),
      details: "2011/04/25",
      realizedGain: Number(Math.random() * 10000).toFixed(2),
      unrealizedGain: Number(Math.random() * 10000).toFixed(2),
    }
    tableData.rows[2] = {
      name: "Tiger Nixon",
      script: "SHIVM",

      unit: Number(Math.random() * 100).toFixed(0),
      wacc: Number(Math.random() * 100).toFixed(2),
      ltp: Number(Math.random() * 100).toFixed(2),
      netPL: Number(Math.random() * 100).toFixed(2),
      commission: Number(Math.random() * 100).toFixed(2),
      details: "2011/04/25",
      realizedGain: Number(Math.random() * 10000).toFixed(2),
      unrealizedGain: Number(Math.random() * 10000).toFixed(2),
    }
  }

  return (
    <div>
      <Card>
        <CardHeader className="bg-primary">View Reports</CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)} id="custom-modal-form">
            <Row className="p-2">
              <Col xl={3} sm={6} className="p-2">
                <label className=" col-form-label  fw-bold fs-6">
                  Customer Name
                </label>
                <Controller
                  control={control}
                  defaultValue={userDropDownList.map(c => c.value)}
                  name="options"
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      className="p-0 m-0"
                      inputRef={ref}
                      value={value}
                      onChange={val => onChange(val)}
                      options={userDropDownList}
                      isClearable
                      isMulti={false}
                    />
                  )}
                />

                {errors?.options && (
                  <FormErrorMessage
                    errorMessage={errors.customerName.message}
                  ></FormErrorMessage>
                )}
              </Col>
              <Col xl={3} sm={6} className="p-2">
                <label className=" col-form-label  fw-bold fs-6">
                  Date (from)
                </label>
                <input
                  type="date"
                  style={{
                    border: errors?.startDate ? "1px solid red" : "",
                  }}
                  {...register("startDate", {
                    required: " required",
                  })}
                  max={moment(new Date()).format("YYYY-MM-DD")}
                  name="startDate"
                  onChange={e => setStartDateFrom(e.target.value)}
                  className="form-control form-control form-control-solid"
                />
                {errors?.startDate && (
                  <FormErrorMessage
                    errorMessage={errors.startDate.message}
                  ></FormErrorMessage>
                )}
              </Col>
              <Col xl={3} sm={6} className="p-2">
                <label className=" col-form-label  fw-bold fs-6">
                  Date (to)
                </label>
                <input
                  style={{
                    border: errors?.endDate ? "1px solid red" : "",
                  }}
                  {...register("endDate", {
                    required: " required",
                  })}
                  type="date"
                  min={moment(new Date(startDateFrom)).format("YYYY-MM-DD")}
                  max={moment(new Date()).format("YYYY-MM-DD")}
                  name="endDate"
                  onChange={e => setEndDateFrom(e.target.value)}
                  className="form-control form-control form-control-solid"
                />
                {errors?.endDate && (
                  <FormErrorMessage
                    errorMessage={errors.endDate.message}
                  ></FormErrorMessage>
                )}
              </Col>
              <Col xl={3} sm={6} className="p-2">
                <label
                  className=" col-form-label  fw-bold fs-6"
                  style={{ visibility: "hidden" }}
                >
                  Customer Name
                </label>
                <div>
                  <Button
                    className="btn"
                    variant={"success"}
                    color={"success"}
                    type={"submit"}
                  >
                    Search Report
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <Card className="pt-4">
        <Row className="p-2">
          <Col xl={12} sm={12} id="data-table-id">
            <MDBDataTable
              responsive
              striped
              noBottomColumns={true}
              data={tableData}
            />
          </Col>
        </Row>
      </Card>
    </div>
  )
}
export default connect(null, { setBreadcrumbItems })(ReportSearch)
