import { QueryClient, useMutation, useQueryClient } from "react-query"
import { useQuery } from "react-query"
import { toast } from "react-toastify"

import { api } from "../../api"
import { httpClient } from "../../http-helpers"

const getAllUserList = () => () => {
  return httpClient.get(api.pmsService.pmsUser.pmsUserGetAll)
}
const getUserById = userId => () => {
  return httpClient.get(api.pmsService.pmsUser.getUserById, {
    params: { userId: userId },
  })
}
const createUserClient = body => {
  return httpClient.post(api.pmsService.pmsUser.pmsUserCreateUser, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}
const putClientById = body => {
  return httpClient.put(api.pmsService.pmsUser.putUserById, body, {
    params: { userId: body.userId },
  })
}

const getUSerInvestmentById = userId => () => {
  return httpClient.get(api.pmsService.pmsUser.getUserInvestment, {
    params: { userId: userId },
  })
}

const postClientInvestment = body => {
  return httpClient.post(api.pmsService.pmsUser.postInvestment, body)
}
const putClientInvestment = body => {
  return httpClient.put(api.pmsService.pmsUser.postInvestment, body)
}
const deleteClientInvestment = body => {
  return httpClient.delete(
    api.pmsService.pmsUser.deleteInvestment.replace("${id}", body.transactionId)
  )
}
const postDocumentOfCustomer = body => {
  return httpClient.post(api.pmsService.pmsUser.postDocument, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}
const getUserDocumentById = userId => () => {
  return httpClient.get(api.pmsService.pmsUser.getDocumentOfUserById, {
    params: { userId: userId },
  })
}
const postUserMessage = body => {
  return httpClient.post(api.pmsService.pmsUser.postUserMessage, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}
const deleteUserMessage = body => {
  console.log(body)
  return httpClient.delete(
    api.pmsService.pmsUser.deleteUserMessage.replace("{id}", body.messageId)
  )
}
const getUserMessageById = userId => () => {
  return httpClient.get(api.pmsService.pmsUser.postUserMessage, {
    params: { userId: userId },
  })
}

export const useGetAllUserList = () => {
  return useQuery(api.pmsService.pmsUser.pmsUserGetAll, getAllUserList(), {
    select: response => response.data,
    onError: error => {
      //   toastFail(error?.response?.data?.message || "Something Went Wrong");
    },
  })
}

export const useCreatePmsUserClient = () => {
  const queryClient = useQueryClient()
  return useMutation(
    api.pmsService.pmsUser.pmsUserCreateUser,
    createUserClient,
    {
      onSuccess: response => {
        queryClient.invalidateQueries(api.pmsService.pmsUser.pmsUserGetAll)
        toast.success(response?.data?.message)
      },

      onError: error => {
        toast.error(error?.response?.data?.message)
      },
    }
  )
}

export const useGetUSerById = userId => {
  return useQuery(
    [api.pmsService.pmsUser.getUserById, userId],
    getUserById(userId),
    {
      enabled: !!userId, // lazy query i.e. script na aako bela samma trigger hunna
      select: response => response.data,
      onError: error => {
        //   toastFail(error?.response?.data?.message || "Something Went Wrong");
      },
    }
  )
}

export const usePutClientByID = body => {
  const queryClient = useQueryClient()
  return useMutation(api.pmsService.pmsUser.putUserById, putClientById, {
    onSuccess: (response, variables) => {
      const { userId } = variables
      queryClient.invalidateQueries(api.pmsService.pmsUser.pmsUserGetAll)
      queryClient.invalidateQueries([
        api.pmsService.pmsUser.getUserById,
        userId,
      ])
      toast.success(response?.data?.message)
    },

    onError: error => {
      toast.error(error?.response?.data?.message)
    },
  })
}

export const useGetUserInvestmentById = userId => {
  return useQuery(
    [api.pmsService.pmsUser.getUserInvestment, userId],
    getUSerInvestmentById(userId),
    {
      enabled: !!userId, // lazy query i.e. script na aako bela samma trigger hunna
      select: response => response.data,
      onError: error => {
        //   toastFail(error?.response?.data?.message || "Something Went Wrong");
      },
    }
  )
}

export const usePostClientInvestment = body => {
  const queryClient = useQueryClient()
  return useMutation(
    api.pmsService.pmsUser.postInvestment,
    postClientInvestment,
    {
      onSuccess: (response, variables) => {
        console.log(body)
        const { userId } = variables

        queryClient.invalidateQueries(
          api.pmsService.pmsReport.getSymmurayByAdmin
        )

        queryClient.invalidateQueries([
          api.pmsService.pmsUser.getUserInvestment,
          userId,
        ])
        queryClient.invalidateQueries([
          api.pmsService.pmsReport.getSymmurayByAdmin,
          body.userId,
        ])
        toast.success(response?.data?.message)
      },

      onError: error => {
        toast.error(error?.response?.data?.message)
      },
    }
  )
}
export const usePutClientInvestment = body => {
  const queryClient = useQueryClient()
  console.log(body)
  return useMutation(
    api.pmsService.pmsUser.postInvestment,
    putClientInvestment,
    {
      onSuccess: (response, variables) => {
        const { userId } = variables

        queryClient.invalidateQueries(
          api.pmsService.pmsReport.getSymmurayByAdmin
        )

        queryClient.invalidateQueries([
          api.pmsService.pmsUser.getUserInvestment,
          userId,
        ])
        queryClient.invalidateQueries([
          api.pmsService.pmsReport.getSymmurayByAdmin,
          body.userId,
        ])

        toast.success(response?.data?.message)
      },

      onError: error => {
        toast.error(error?.response?.data?.message)
      },
    }
  )
}
export const useDeleteClientInvestment = body => {
  const queryClient = useQueryClient()
  console.log(body)
  return useMutation(
    api.pmsService.pmsUser.deleteInvestment,
    deleteClientInvestment,
    {
      onSuccess: (response, variables) => {
        const { userId } = variables

        queryClient.invalidateQueries(
          api.pmsService.pmsReport.getSymmurayByAdmin
        )

        queryClient.invalidateQueries([
          api.pmsService.pmsUser.getUserInvestment,
          userId,
        ])
        queryClient.invalidateQueries([
          api.pmsService.pmsReport.getSymmurayByAdmin,
          body.userId,
        ])

        toast.success(response?.data?.message)
      },

      onError: error => {
        toast.error(error?.response?.data?.message)
      },
    }
  )
}

export const usePostDocumentOfClient = body => {
  const queryClient = useQueryClient()
  return useMutation(
    api.pmsService.pmsUser.postDocument,
    postDocumentOfCustomer,
    {
      onSuccess: (response, variables) => {
        console.log(variables, body)

        queryClient.invalidateQueries([
          api.pmsService.pmsUser.getDocumentOfUserById,
          response?.data?.data?.userId,
        ])

        toast.success(response?.data?.message)
      },

      onError: (error, variables) => {
        console.log(variables, userId)
        toast.error(error?.response?.data?.message)
      },
    }
  )
}

export const useGetDocumentOfCustomerById = userId => {
  return useQuery(
    [api.pmsService.pmsUser.getDocumentOfUserById, userId],
    getUserDocumentById(userId),
    {
      enabled: !!userId, // lazy query i.e. script na aako bela samma trigger hunna
      select: response => response.data,
      onError: error => {
        //   toastFail(error?.response?.data?.message || "Something Went Wrong");
      },
    }
  )
}
export const usePostUserMessage = body => {
  const queryClient = useQueryClient()
  return useMutation(api.pmsService.pmsUser.postUserMessage, postUserMessage, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries([
        api.pmsService.pmsUser.postUserMessage,
        response?.data?.data?.userId,
      ])

      toast.success(response?.data?.message)
    },

    onError: (error, variables) => {
      console.log(variables, userId)
      toast.error(error?.response?.data?.message)
    },
  })
}
export const useDeleteUserMessage = body => {
  const queryClient = useQueryClient()
  return useMutation(
    api.pmsService.pmsUser.postUserMessage,
    deleteUserMessage,
    {
      onSuccess: (response, variables) => {
        const { userId } = variables
        queryClient.invalidateQueries([
          api.pmsService.pmsUser.postUserMessage,
          userId,
        ])

        toast.success(response?.data?.message)
      },

      onError: (error, variables) => {
        toast.error(error?.response?.data?.message)
      },
    }
  )
}
export const useGetUserMessageById = userId => {
  return useQuery(
    [api.pmsService.pmsUser.postUserMessage, userId],
    getUserMessageById(userId),
    {
      enabled: !!userId, // lazy query i.e. script na aako bela samma trigger hunna
      select: response => response.data,
      onError: error => {
        //   toastFail(error?.response?.data?.message || "Something Went Wrong");
      },
    }
  )
}
