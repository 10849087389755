import React, { Suspense, useEffect, useState } from "react"
import { Card, CardBody, CardHeader, Row } from "reactstrap"

import { useParams } from "react-router-dom"
const ClientInvestMent = React.lazy(() =>
  import("./ClientProfile/ClientInvestMent")
)

const UploadFile = React.lazy(() => import("./ClientProfile/UploadFile"))
const Messages = React.lazy(() => import("./ClientProfile/Messages"))
const KycForm = React.lazy(() => import("./ClientProfile/KycForm"))
const ClientProtfolio = React.lazy(() =>
  import("./ClientProfile/ClientProtfolio")
)
const ClientProfile = React.lazy(() => import("./ClientProfile/ClientProfile"))
const ClientAddHolings = React.lazy(() =>
  import("./ClientProfile/ClientAddHolings")
)
// import { getUserDetails } from "../../apis/user"
import { useGetUSerById } from "services/fetchers/pmsservice/pmsuser"

export default function Settings() {
  const [selectedTab, setSelectedTab] = useState("pills-profile")
  const [data, setData] = useState([])
  const [image, setImage] = useState(null)
  const [imageUrl, setImageUrl] = useState("")
  const { id } = useParams()
  const userDetailById = useGetUSerById(id)
  console.log(id)
  const staticBaseUrl = "https://res-rlch.s3.ap-south-1.amazonaws.com/"

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUserDetails()
        console.log(response)
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (image) {
      setImageUrl(URL.createObjectURL(image))
    }
  }, [image])

  return (
    <Card>
      <div class="container-fluid settings">
        <CardHeader className="bg-primary">
          <h5 className="text-white">
            Profile Settings
            {userDetailById?.data?.data[0] &&
              ": " +
                userDetailById?.data?.data[0]?.first_name +
                " " +
                userDetailById?.data?.data[0]?.middle_name +
                " " +
                userDetailById?.data?.data[0]?.last_name}{" "}
          </h5>
        </CardHeader>
        <CardBody>
          <div className="settings-tab">
            <ul
              className="nav nav-pills text-white"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    selectedTab === "pills-profile" && "active"
                  }`}
                  onClick={() => setSelectedTab("pills-profile")}
                >
                  Profile
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    selectedTab === "pills-investment-tab" && "active"
                  }`}
                  onClick={() => setSelectedTab("pills-investment-tab")}
                >
                  Investment
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    selectedTab === "pills-protfilio-tab" && "active"
                  }`}
                  onClick={() => setSelectedTab("pills-protfilio-tab")}
                >
                  Portfolio
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    selectedTab === "pills-addholding-tab" && "active"
                  }`}
                  onClick={() => setSelectedTab("pills-addholding-tab")}
                >
                  Add Holdings
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    selectedTab === "pills-fileUpload-tab" && "active"
                  }`}
                  onClick={() => setSelectedTab("pills-fileUpload-tab")}
                >
                  Documents
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    selectedTab === "pills-messages-tab" && "active"
                  }`}
                  onClick={() => setSelectedTab("pills-messages-tab")}
                >
                  Messages
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    selectedTab === "pills-kyc-tab" && "active"
                  }`}
                  onClick={() => setSelectedTab("pills-kyc-tab")}
                >
                  KYC Form
                </button>
              </li>
            </ul>

            <div className="tab-content pt-2" id="pills-tabContent">
              <div
                className={`tab-pane fade ${
                  selectedTab === "pills-profile" && "show active"
                }`}
                id="pills-profile"
                role="tabpanel"
              >
                <Suspense fallback={<div></div>}>
                  <ClientProfile userId={id} />
                </Suspense>
              </div>
              <div
                className={`tab-pane fade ${
                  selectedTab === "pills-investment-tab" && "show active"
                }`}
                id="pills-investment-tab"
                role="tabpanel"
              >
                {" "}
                <Suspense fallback={<div></div>}>
                  <ClientInvestMent userId={id} />
                </Suspense>
              </div>
              <div
                className={`tab-pane fade ${
                  selectedTab === "pills-addholding-tab" && "show active"
                }`}
                id="pills-addholding-tab"
                role="tabpanel"
              >
                {" "}
                <Suspense fallback={<div></div>}>
                  <ClientAddHolings userId={id} />
                </Suspense>
              </div>
              <div
                className={`tab-pane fade ${
                  selectedTab === "pills-fileUpload-tab" && "show active"
                }`}
                id="pills-fileUpload-tab"
                role="tabpanel"
              >
                {" "}
                <Suspense fallback={<div></div>}>
                  <UploadFile userId={id}></UploadFile>
                </Suspense>
              </div>
              <div
                className={`tab-pane fade ${
                  selectedTab === "pills-messages-tab" && "show active"
                }`}
                id="pills-messages-tab"
                role="tabpanel"
              >
                {" "}
                <Suspense fallback={<div></div>}>
                  <Messages userId={id}></Messages>
                </Suspense>
              </div>
              <div
                className={`tab-pane fade ${
                  selectedTab === "pills-kyc-tab" && "show active"
                }`}
                id="pills-kyc-tab"
                role="tabpanel"
              >
                {" "}
                <Suspense fallback={<div></div>}>
                  <KycForm userId={id}></KycForm>
                </Suspense>
              </div>
              <div
                className={`tab-pane fade ${
                  selectedTab === "pills-protfilio-tab" && "show active"
                }`}
                id="pills-protfilio-tab"
                role="tabpanel"
              >
                {" "}
                <Suspense fallback={<div></div>}>
                  <ClientProtfolio userId={id}></ClientProtfolio>
                </Suspense>
              </div>
            </div>
          </div>
        </CardBody>
      </div>
    </Card>
  )
}
