import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useForm, useFieldArray, Controller } from "react-hook-form"
import { setBreadcrumbItems } from "../../../store/actions"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Spinner,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import FormErrorMessage from "components/Common/FormErrorMessage"
import Select from "react-select"
import { useAllStocksInNepse } from "services/fetchers/nepse/nepse"
import { useGetAllUserList } from "services/fetchers/pmsservice/pmsuser"
import { usePostPmsTransaction } from "services/fetchers/pmsservice/pmsTransaction"
import { toast } from "react-toastify"
function AddHoldings(props) {
  const breadcrumbItems = [
    { title: "Home", link: "/" },
    { title: "Add Holdings", link: "/add-holdings" },
  ]
  useEffect(() => {
    props.setBreadcrumbItems("Modals", breadcrumbItems)
  }, [])

  // all api data integration based on our need

  const allStocksInNepse = useAllStocksInNepse()
  const allUserList = useGetAllUserList()
  const {
    mutateAsync,
    error,
    mutate,
    isError,
    isLoading: isLoadingCreateUser,
  } = usePostPmsTransaction()

  //states to hold the value of select dropdown
  const [stocksDropdownList, setStocksDropDownList] = useState([])
  const [userDropDownList, setUserDropDownList] = useState([])
  const [txnType] = useState([
    { label: "Buy", value: "buy" },
    { label: "Bonous", value: "bonous" },
    { label: "Right Share", value: "right" },
    { label: "Dividend", value: "dividend" },
    { label: "Sell", value: "sell" },
    { label: "IPO", value: "ipo" },
    { label: "FPO", value: "fpo" },
  ])

  useEffect(() => {
    if (allUserList?.data?.data?.length > 0) {
      const tempArray = []
      allUserList?.data?.data.forEach(element => {
        const tempDict = {}
        const fullName = element["middle_name"]
          ? element["first_name"] +
            " " +
            element["middle_name"] +
            " " +
            element["last_name"]
          : element["first_name"] + " " + element["last_name"]
        tempDict.label = fullName
        tempDict.value = element["user_id"]
        tempArray.push(tempDict)
      })
      setUserDropDownList(() => tempArray)
    }
  }, [allUserList?.data?.data])

  useEffect(() => {
    if (allStocksInNepse?.data?.data?.length > 0) {
      const tempArray = []
      allStocksInNepse?.data?.data.forEach(element => {
        const tempDict = {}
        tempDict.label = element.symbol
        tempDict.value = element.symbol
        tempArray.push(tempDict)
      })
      setStocksDropDownList(() => tempArray)
    }
  }, [allStocksInNepse?.data?.data])

  // for maintaining the key of the array for usefield array
  const [tempId, setTempId] = useState(1)
  const {
    register: register,
    formState: { errors: errors },
    handleSubmit: handleSubmit,
    watch: watch,

    control: control,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      addHoldings: [
        {
          tempId: 0,
          customerName: "",
          companyName: "",
          txnType: "",
          txnDate: "",
          shareQuantity: "",
          rateAmount: "",
        },
      ],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "addHoldings",
  })
  const onSubmit = async data => {
    if (watch("addHoldings").length > 0) {
      const addHoldingsData = watch("addHoldings")
      try {
        for (const element of addHoldingsData) {
          const tempDict = {
            transactionType: element.txnType?.value,
            quantity: Number(element.shareQuantity),
            price: Number(element.rateAmount),
            date: element.txnDate,
            stockId: element.companyName?.value,
            amount: Number(element.shareQuantity) * Number(element.rateAmount),
            pmsUserId: element.customerName?.value,
          }

          const response = await mutateAsync(tempDict)

          if (response.data.statusCode !== 200) {
            console.error("Error:", response.message)

            throw new Error("Some API calls failed.")
          }
        }

        toast.success("Saved all holdings")
        reset({
          addHoldings: [
            {
              tempId: 0,
              customerName: "",
              companyName: "",
              txnType: "",
              txnDate: "",
              shareQuantity: "",
              rateAmount: "",
            },
          ],
        })
      } catch (error) {
        console.error("Error:", error.message) // Handle any unexpected errors
      }
    } else {
      toast.error("Insert required information first")
    }
  }
  return (
    <div>
      <Card>
        <CardHeader className="bg-primary text-white">Add Holdings</CardHeader>
        <CardBody className="p-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((item, index) => {
              return (
                <Row key={item.tempId} className="p-2">
                  <Col sm={3} lg={2}>
                    <label className=" col-form-label  fw-bold fs-6">
                      Customer Name
                    </label>

                    <Controller
                      control={control}
                      name={`addHoldings[${index}].customerName`}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          className="p-0 m-0"
                          value={value}
                          onChange={val => onChange(val)}
                          options={userDropDownList}
                          isClearable
                          isMulti={false}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={3} lg={2}>
                    <label className=" col-form-label  fw-bold fs-6">
                      Company Name
                    </label>

                    <Controller
                      control={control}
                      name={`addHoldings[${index}].companyName`}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          className="p-0 m-0"
                          value={value}
                          onChange={val => onChange(val)}
                          options={stocksDropdownList}
                          isClearable
                          isMulti={false}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={3} lg={2}>
                    <label className=" col-form-label  fw-bold fs-6">
                      Txn Type
                    </label>

                    <Controller
                      control={control}
                      name={`addHoldings[${index}].txnType`}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          className="p-0 m-0"
                          value={value}
                          onChange={val => onChange(val)}
                          options={txnType}
                          isClearable
                          isMulti={false}
                        />
                      )}
                    />
                  </Col>

                  <Col sm={3} lg={2}>
                    <label className=" col-form-label  fw-bold fs-6">
                      Txn Date
                    </label>

                    <Controller
                      control={control}
                      name={`addHoldings[${index}].txnDate`}
                      render={({ field: { onChange, value } }) => (
                        <input
                          type="date"
                          max={new Date()?.toISOString()?.split("T")[0]}
                          className="form-control form-control form-control-solid"
                          value={value}
                          onChange={val => onChange(val)}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={3} lg={2}>
                    <label className=" col-form-label  fw-bold fs-6">
                      Quantity
                    </label>

                    <Controller
                      control={control}
                      name={`addHoldings[${index}].shareQuantity`}
                      render={({ field: { onChange, value } }) => (
                        <input
                          type="number"
                          className="form-control form-control form-control-solid"
                          value={value}
                          onChange={val => onChange(val)}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={3} lg={1}>
                    <label className=" col-form-label  fw-bold fs-6">
                      Price
                    </label>

                    <Controller
                      control={control}
                      name={`addHoldings[${index}].rateAmount`}
                      render={({ field: { onChange, value } }) => (
                        <input
                          type="number"
                          className="form-control form-control form-control-solid"
                          value={value}
                          onChange={val => onChange(val)}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={3} lg={1}>
                    <label
                      className=" col-form-label  fw-bold fs-6"
                      style={{ visibility: "hidden" }}
                    >
                      C
                    </label>
                    <div className="d-flex justify-content-between">
                      <a
                        className="btn text-danger"
                        onClick={() => {
                          remove(item.tempId)
                        }}
                      >
                        <i className="mdi mdi-delete-outline"></i>
                      </a>
                      <a
                        className="btn text-primary"
                        onClick={() => {
                          append({
                            tempId: tempId,
                            customerName: "",
                            companyName: "",
                            txnType: "",
                            txnDate: "",
                            shareQuantity: "",
                            rateAmount: "",
                          })
                          setTempId(tempId => tempId + 1)
                        }}
                      >
                        <i className="mdi mdi-plus-circle-outline "></i>
                      </a>
                    </div>
                  </Col>
                </Row>
              )
            })}

            <Row>
              <div className="d-flex justify-content-end p-2">
                {!isLoadingCreateUser && (
                  <Button
                    className="btn"
                    variant={"primary"}
                    color={"primary"}
                    type={"submit"}
                  >
                    Add All Holdings
                  </Button>
                )}
                {isLoadingCreateUser && (
                  <Button variant="success" color="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Loading...</span>
                  </Button>
                )}
              </div>
            </Row>
          </form>
        </CardBody>
      </Card>
    </div>
  )
}

export default connect(null, { setBreadcrumbItems })(AddHoldings)
