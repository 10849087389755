import React, { useState } from "react"
import { Button, Card, CardHeader, Col, Input, Row, Spinner } from "reactstrap"
import { useGetAllNewsLetter } from "services/fetchers/newsletter/newsletterservice"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js"
import { useDropzone } from "react-dropzone"
import { usePostUserMessage } from "services/fetchers/newsletter/newsletterservice"

import draftToHtml from "draftjs-to-html"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { toFormData } from "services"
function Newsletter(props) {
  const {
    mutateAsync,
    error,
    mutate,
    isError,
    isSuccess,
    isLoading: isLoadingCreateUser1,
  } = usePostUserMessage()

  const staticBaseUrl = "https://res-rlch.s3.ap-south-1.amazonaws.com/"
  const allNewsLetters = useGetAllNewsLetter()

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [title, setTitle] = useState("")
  const [subject, setSubject] = useState("")
  const [thumbnailImage, setThumbnailImage] = useState("")
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      maxFiles: 1,
    })

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))
  const handleSubmitForm = async e => {
    e.preventDefault()
    console.log(thumbnailImage)
    const tempPayload = {
      title: title,
      shortDescription: subject,
      longDescription: draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ),
      date: new Date().toLocaleDateString(),
    }

    const tempFormDataPayload = toFormData(tempPayload)
    if (acceptedFiles[0]) {
      tempFormDataPayload.append("document", acceptedFiles[0])
    } else {
      tempFormDataPayload.append("document", "")
    }
    if (thumbnailImage?.file) {
      tempFormDataPayload.append("thumbnail", thumbnailImage?.file)
    } else {
      tempFormDataPayload.append("thumbnail", "")
    }

    const responseData = await mutateAsync(tempFormDataPayload)

    if (responseData.data.statusCode == 200) {
      isLoadingCreateUser1 = false
      if (fileId) {
      }
    }
  }

  return (
    <div className="">
      {console.log(isLoadingCreateUser1)}
      <Card className="p-2">
        <CardHeader className="bg-primary text-white">Newsletter</CardHeader>
        <div>
          <div className=" mb-3 px-5">
            <form onSubmit={handleSubmitForm}>
              <div className="card-body">
                <div>
                  <div className="mb-3">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      onChange={e => setTitle(() => e.target.value)}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      onChange={e => setSubject(() => e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={contentState =>
                        setEditorState(contentState)
                      }
                    />
                  </div>
                  <div
                    className="d-lg-flex d-block"
                    style={{ justifyContent: "between" }}
                  >
                    <div className="w-50 p-2">
                      <label className=" col-form-label  fw-bold fs-6">
                        Import File
                      </label>
                      <div {...getRootProps({ className: "dropzone p-4" })}>
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                        <em>(only 1 file is accepted)</em>
                      </div>

                      <aside>
                        <ul>{acceptedFileItems}</ul>
                      </aside>
                    </div>
                    <div className="w-50 p-2">
                      <label className=" col-form-label  fw-bold fs-6">
                        Thumbnail Image
                      </label>
                      <div>
                        <div>
                          <input
                            type="file"
                            accept="image/*"
                            required="true"
                            onChange={e => {
                              const file = e.target.files[0]
                              const fileUrl = URL?.createObjectURL(file)
                              setThumbnailImage({ fileUrl: fileUrl, img: file })
                            }}
                          ></input>
                          {thumbnailImage?.fileUrl && (
                            <div>
                              <div className="d-flex justify-conetnt-center p-3">
                                <div
                                  style={{ height: "180px", width: "180px" }}
                                >
                                  <img
                                    src={thumbnailImage?.fileUrl}
                                    style={{
                                      height: "180px",
                                      width: "auto",
                                      borderRadius: "16px",
                                    }}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  {!isLoadingCreateUser1 && (
                    <Button
                      className="btn"
                      variant={"primary"}
                      color={"primary"}
                      type={"submit"}
                    >
                      Publish Newsletter
                    </Button>
                  )}
                  {isLoadingCreateUser1 && (
                    <Button variant="success" color="primary" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Loading...</span>
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="pt-4">
          <CardHeader className="bg-primary text-white">
            Preview newsletters
          </CardHeader>
          <div>
            {allNewsLetters?.isLoading && (
              <div className="text-center">Loading...</div>
            )}
            {allNewsLetters?.isError && (
              <div className="text-center text-danger">
                Something went wrong
              </div>
            )}
            {allNewsLetters?.data?.data?.length > 0 ? (
              <div className=" pt-2">
                <Row>
                  {allNewsLetters?.data?.data?.map(element => {
                    return (
                      <Col xl="4" md="6" key={element.id}>
                        <Card className="directory-card">
                          <a
                            onClick={() =>
                              window.open(
                                staticBaseUrl + element.document,
                                "_blank"
                              )
                            }
                          >
                            <div
                              className="text-center"
                              style={{
                                height: "100px",
                                backgroundImage: `https://res-rlch.s3.ap-south-1.amazonaws.com/
                                  ${element?.thumbnail}
                                 `,
                              }}
                            >
                              <div className="directory-overlay">
                                {/* <img className="rounded-circle avatar-lg img-thumbnail" src={getImageUrl(element.thumbnail)} alt="Generic placeholder" /> */}
                              </div>
                            </div>

                            <div className="directory-content text-center p-4">
                              <p className=" mt-2">{element.date}</p>
                              <h5 className="font-size-16">{element.title}</h5>
                              <p className="text-muted">
                                {element.shortDescription}
                              </p>
                            </div>
                          </a>
                        </Card>
                      </Col>
                    )
                  })}
                </Row>
              </div>
            ) : (
              <div className="txt-center pt-2">No data to preview</div>
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default Newsletter
