import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Table } from "reactstrap"
import StockSummary from "../clients/Summary/StockSummary"
import DividendAnnouncement from "../clients/Summary/DividendAnnouncement"
import NepseData from "../clients/Summary/NepseData"
import SummaryData from "../clients/Summary/SummaryData"
import WithdrawDetails from "../clients/Summary/WithdrawDetails"
import PieChart from "../charts/PieChart"

const ClientReportDetail = props => {
  const [data, setData] = useState()

  // useEffect(()=>{
  //   const fetchData = async() => {
  //     try {
  //       const response = await getUserReport();
  //       console.log(response)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   fetchData();
  // },[])
  return (
    <React.Fragment>
      <h1 className="font-size-24">Portfolio Summary</h1>
      <Card>
        <CardBody>
          <CardTitle className="card-title mb-2 text-white">
            Portfolio Summary Report
          </CardTitle>
          <div className="table-responsive">
            <div className="portfolio-grid">
              <div className="portfolio-item">
                Portfolio: <span>Dummy</span>
              </div>
              <div className="portfolio-item">
                Agreement Date: <span>2079-04-06</span>
              </div>
              <div className="portfolio-item">
                Portfolio Growth: <span>50%</span>
              </div>
              <div className="portfolio-item">
                As on: <span>2079-04-06</span>
              </div>
              <div className="portfolio-item">
                Expiry Date: <span>2079-04-06</span>
              </div>
              <div className="portfolio-item">
                Inv. Amount: <span>1,000,000,000</span>
              </div>
              <div className="portfolio-item">
                AMF Rate: <span>1.00</span>
              </div>
              <div className="portfolio-item">
                PF Rate: <span>10.00</span>
              </div>
              <div className="portfolio-item">
                Portfolio Growth Value: <span>20,000</span>
              </div>
              <div className="portfolio-item">
                Relation Manager: <span>PMS Department</span>
              </div>
              <div className="portfolio-item">
                RM Mobile No: <span>9863782663</span>
              </div>
              <div className="portfolio-item">
                Portfolio Val.(HLDG): <span>1,200,000,000</span>
              </div>
            </div>
          </div>

          <div className="my-4">
            <StockSummary />
          </div>

          <div className="my-4">
            <DividendAnnouncement />
          </div>

          <div className="my-4">
            <WithdrawDetails />
          </div>

          <div className="my-4">
            <SummaryData />
          </div>

          <div className="my-4">
            <NepseData />
          </div>

          <div className="my-4 mt-8">
            <PieChart />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ClientReportDetail
