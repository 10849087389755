import React, { useEffect, useState, useRef, useMemo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faWallet,
  faDollarSign,
  faCoins,
  faEllipsisH,
  faFilter,
  faSearch,
  faFileExport,
  faChevronDown,
  faUsers,
  faUserPlus,
  faBriefcase,
  faDownload,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons"

import "primeicons/primeicons.css"

import { FaEye } from "react-icons/fa"
import { connect } from "react-redux"
import { useForm } from "react-hook-form"
import { setBreadcrumbItems } from "../../../store/actions"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import LabelNameToInputMapper from "components/Common/LabelNameToInputMapper"
import BtnNameToButtonMapper from "components/Common/BtnNameToButtonMapper"
import { toast } from "react-toastify"

// for api integration
import { useCreatePmsUserClient } from "services/fetchers/pmsservice/pmsuser"
import { useGetAllUserList } from "services/fetchers/pmsservice/pmsuser"
import { Link } from "react-router-dom"
import FormErrorMessage from "components/Common/FormErrorMessage"
import { toFormData } from "services"
import CommonCard from "./CommonCard"

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import ClientAdd from "./ClientAdd"

function ClientList(props) {
  const [searchTerm, setSearchTerm] = useState("")
  const paginatorLeft = (
    <Button type="button" style={{ color: "black" }}>
      <FontAwesomeIcon icon={faSyncAlt} />
    </Button>
  )

  const paginatorRight = (
    <Button type="button" style={{ color: "black" }}>
      <FontAwesomeIcon icon={faDownload} />
    </Button>
  )
  const staticData = [
    {
      full_name: "John Doe",
      phone: { name: "USA" },
      agreement_date: "Company A",
      commission_rate: { name: "Rep A" },
    },
    {
      name: "Jane Smith",
      country: { name: "Canada" },
      company: "Company B",
      representative: { name: "Rep B" },
    },
    {
      name: "John Doe",
      country: { name: "USA" },
      company: "Company A",
      representative: { name: "Rep A" },
    },
    {
      name: "Jane Smith",
      country: { name: "Canada" },
      company: "Company B",
      representative: { name: "Rep B" },
    },
    {
      name: "John Doe",
      country: { name: "USA" },
      company: "Company A",
      representative: { name: "Rep A" },
    },
    {
      name: "Jane Smith",
      country: { name: "Canada" },
      company: "Company B",
      representative: { name: "Rep B" },
    },
  ]

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2 py-2">
      <span className="text-[18px] text-900 font-semibold">Products</span>
    </div>
  )

  const breadcrumbItems = [
    { title: "Home", link: "/" },
    { title: "Clients", link: "/clients" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Modals", breadcrumbItems)
  })

  const {
    mutateAsync,
    error,
    mutate,
    isError,
    isSuccess,
    isLoading: isLoadingCreateUser,
  } = useCreatePmsUserClient()

  const data = {
    columns: [
      {
        label: "Full Name",
        field: "full_name",
        sort: "asc",
        width: 250,
      },
      {
        label: "Phone Number",
        field: "phone",
        sort: "asc",
        width: 250,
      },
      // {
      //   label: "Email",
      //   field: "email",
      //   sort: "asc",
      //   width: 250,
      // },

      {
        label: "Agreement Date",
        field: "agreement_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Commission Rate %",
        field: "commission_rate",
        sort: "asc",
        width: 120,
      },
      {
        label: "Detailed View",
        field: " <FontAwesomeIcon icon={faEllipsisH} />",
        sort: "asc",
        width: 120,
      },
    ],
    rows: [],
  }

  const getAllUserList = useGetAllUserList()

  if (getAllUserList?.data?.data?.length > 0) {
    const tempArray = []
    getAllUserList?.data?.data.forEach((element, index) => {
      const tempDict = {}
      const tempFullName = (
        <div>
          <Link to={`/clients/settings/${element["user_id"]}`}>
            {element["middle_name"]
              ? element["first_name"] +
                " " +
                element["middle_name"] +
                " " +
                element["last_name"]
              : element["first_name"] + " " + element["last_name"]}
          </Link>
        </div>
      )

      tempDict["full_name"] = tempFullName
      tempDict["phone"] = element["phone"]
      tempDict["email"] = element["email"]
      tempDict["agreement_date"] = element["agreement_date"]
      tempDict["commission_rate"] = element["commission_rate"]

      tempArray.push(tempDict)
    })
    data.rows = tempArray
  }

  // for user crud
  const [currentAuthorization, setCurrentAuthorization] = useState("")
  const [currntSelectedValue, setCurrntSelectedValue] = useState(" ")
  const [showModal, setShowModal] = useState(false)
  const [currentValue, setCurrentValue] = useState("")
  const [formReturnData, setFormReturnData] = useState("")
  const [isLoadingLoader, setIsLoadingLoader] = useState(false)

  const [btnName, setBtnName] = useState([
    { name: "cancel", color: "danger", type: "cancel" },
    { name: "Save ", color: "success", type: "submit" },
  ])
  useEffect(() => {
    if (currentAuthorization == "") {
      setBtnName([
        { name: "Cancel", color: "danger", type: "cancel" },
        { name: "Save ", color: "success", type: "submit" },
      ])
    }
    if (currentAuthorization == "read") {
      setBtnName([{ name: "Cancel", color: "danger", type: "cancel" }])
    }
    if (currentAuthorization == "update") {
      setBtnName([
        { name: "Cancel", color: "danger", type: "cancel" },
        { name: "Update ", color: "primary", type: "submit" },
      ])
    }
    if (currentAuthorization == "delete") {
      setBtnName([
        { name: "cancel", color: "primary", type: "cancel" },
        { name: "Delete", color: "danger", type: "submit" },
      ])
    }
  }, [currentAuthorization])

  const [labelNames] = useState([
    {
      name: "First Name",
      formName: "firstName",
      placeholder: "Enter first name ",

      colClassName: "col-lg-4 col-md-6 col-12",
      type: "text",
      requiredData: {
        required: "required",
        maxLength: {
          value: 100,
          message: "max 100 character supported",
        },
      },
      currentAuthorization: currentAuthorization ?? "create",
      isCapitalize: true,
    },
    {
      name: "Middle Name",
      formName: "middleName",
      placeholder: "Enter middle name ",

      colClassName: "col-lg-4 col-md-6 col-12",
      type: "text",
      requiredData: {
        maxLength: {
          value: 100,
          message: "max 100 character supported",
        },
      },
      currentAuthorization: currentAuthorization ?? "create",
      isCapitalize: true,
    },
    {
      name: "Last Name",
      formName: "lastName",
      placeholder: "Enter last name ",

      colClassName: "col-lg-4 col-md-6 col-12",
      type: "text",
      requiredData: {
        required: "required",
        maxLength: {
          value: 100,
          message: "max 100 character supported",
        },
      },
      currentAuthorization: currentAuthorization ?? "create",
      isCapitalize: true,
    },
    {
      name: "Email Address",
      formName: "email",
      placeholder: "Enter email address ",

      colClassName: "col-lg-4 col-md-6 col-12",
      type: "email",
      requiredData: {
        required: "required",
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: "Invalid email address",
        },
      },
      currentAuthorization: currentAuthorization ?? "create",
    },

    {
      name: "Phone Number",
      formName: "phone",
      placeholder: "Enter phone number",

      colClassName: "col-lg-4 col-md-6 col-12",
      type: "number",
      requiredData: {
        required: " required",
        minLength: {
          value: 8,
          message: "Enter valid phone number",
        },
        maxLength: {
          value: 10,
          message: "Enter valid phone number",
        },
      },
      currentAuthorization: currentAuthorization ?? "create",
    },

    {
      name: "Agreement Date",
      formName: "agreementDate",
      placeholder: "Enter agreement date ",

      colClassName: "col-lg-4 col-md-6 col-12",
      type: "date",
      requiredData: {
        required: " required",
      },
      currentAuthorization: currentAuthorization ?? "create",
    },
    {
      name: "Commission Rate",
      formName: "commissionRate",
      placeholder: "Enter pcommission rate",

      colClassName: "col-lg-4 col-md-6 col-12",
      type: "number",
      requiredData: {
        required: " required",
        min: {
          value: 0,
          message: "Enter valid comission rate",
        },
        max: {
          value: 100,
          message: "Enter valid comission rate",
        },
      },
      currentAuthorization: currentAuthorization ?? "create",
    },
    {
      name: "Leverage Amount",
      formName: "leverageAmount",
      placeholder: "Enter leverage amount",

      colClassName: "col-lg-4 col-md-6 col-12",
      type: "number",
      requiredData: {
        required: " required",
        min: {
          value: 0,
          message: "Enter valid leverage rate",
        },
      },
      currentAuthorization: currentAuthorization ?? "create",
    },
    {
      name: "Commitment Amount",
      formName: "commitmentAmount",
      placeholder: "Enter commitment amount",

      colClassName: "col-lg-4 col-md-6 col-12",
      type: "number",
      requiredData: {
        required: " required",
        min: {
          value: 0,
          message: "Enter valid leverage rate",
        },
      },
      currentAuthorization: currentAuthorization ?? "create",
    },

    {
      name: "Maturity Date",
      formName: "maturityDate",
      placeholder: "Enter maturity date ",
      isNoMax: true,
      colClassName: "col-lg-4 col-md-6 col-12",
      type: "date",
      requiredData: {
        required: " required",
      },
      currentAuthorization: currentAuthorization ?? "create",
    },
    {
      name: "AMF Type",
      formName: "amfType",
      placeholder: "Select AMF Type",

      dictInsideList: false,
      type: "dropdown1",
      dictInsideList: false,
      options: ["Percent", "Fix Rate"],
      colClassName: "col-lg-4 col-md-6 col-12",
      currentAuthorization: currentAuthorization ?? "create",
    },
    {
      name: "AMF Value",
      formName: "amf",
      placeholder: "Enter AMF value",

      colClassName: "col-lg-4 col-md-6 col-12",
      type: "number",
      requiredData: {
        required: " required",
      },
      currentAuthorization: currentAuthorization ?? "create",
    },
    {
      name: "Referee Name",
      formName: "referee",
      placeholder: "Enter referee name",

      colClassName: "col-lg-4 col-md-6 col-12",
      type: "text",

      currentAuthorization: currentAuthorization ?? "create",
    },
    {
      name: "Referee Email",
      formName: "refereeEmail",
      placeholder: "Enter referee name",

      colClassName: "col-lg-4 col-md-6 col-12",
      type: "email",

      currentAuthorization: currentAuthorization ?? "create",
    },
    {
      name: "Referee Commission",
      formName: "refereeCommission",
      placeholder: "Enter commission",

      colClassName: "col-lg-4 col-md-6 col-12",
      type: "number",

      currentAuthorization: currentAuthorization ?? "create",
    },
  ])
  const {
    register: register,
    formState: { errors },
    handleSubmit: handleSubmit,
  } = useForm({})

  const onSubmit = async data => {
    if (!data.middleName) {
      data.middleName = ""
    }

    const formDataPayload = toFormData(data)
    // as the toFormData doenot work for files
    formDataPayload.append("photo", img.img)
    // formDataPayload.delete("photo1[0]")

    for (const entry of formDataPayload) {
      console.log(entry[0], entry[1])
    }
    setIsLoadingLoader(true)
    const responseData = await mutateAsync(formDataPayload)
    console.log(responseData)
    setIsLoadingLoader(false)
    if (responseData.data.statusCode == 200) {
      setShowModal(false)
      setIsLoadingLoader(() => false)

      setCurrntSelectedValue("")
      setCurrentAuthorization("")
    } else {
      setIsLoadingLoader(() => false)
    }
  }

  const fileInputRef = useRef(null)

  const onImageContainerClick = () => {
    // Trigger the file input dialog
    fileInputRef.current.click()
  }
  const [img, setImg] = useState("")
  const onImageChange = e => {
    const file = e.target.files[0]
    const fileUrl = URL.createObjectURL(file)
    setImg({ fileUrl: fileUrl, img: file })
  }

  return (
    <>
      <div>
        {/* Search Input */}

        <div className="flex items-center justify-between  gap-2 mb-3">
          <div className="bg-fill rounded-md gap-2 flex items-center px-3 py-2   border  lg:min-w-[350px] ">
            <FontAwesomeIcon icon={faSearch} />
            <input className="outline-none" type="text" placeholder="Search" />
          </div>
          <div className="flex gap-2 justify-content-end ">
            <div className="bg-fill rounded-md gap-2 flex items-center px-3 py-2   border  ">
              <FontAwesomeIcon icon={faFilter} />
              <button className="outline-none">Filter by</button>
            </div>
            <div className="bg-fill rounded-md gap-2 flex items-center px-3 py-2   border  ">
              <FontAwesomeIcon icon={faFileExport} />
              <button className="outline-none" type="text">
                Export
              </button>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            <div className="bg-fill rounded-md  items-center px-3 py-2 flex gap-2  border   ">
              <button className="outline-none ">Last 6 months</button>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>

            <div className="">
              <Button
                className=" bg-primary px-3 py-2   "
                variant={"success"}
                color={"success"}
                type={"button"}
                onClick={() => {
                  setShowModal(() => true)
                  setCurrentAuthorization(() => false)
                }}
              >
                Add new client
              </Button>
            </div>
          </div>
        </div>
        <div class="flex flex-nowrap overflow-x-auto hide-scrollbar  ">
          <div class="min-w-[280px] ">
            <CommonCard
              title={"Total Clients"}
              count={"$1,980,345"}
              image={<FontAwesomeIcon icon={faUsers} />}
            />
          </div>
          <div class="min-w-[280px]">
            <CommonCard
              title={"Total AMC"}
              count={"$1,980,345"}
              image={<FontAwesomeIcon icon={faBriefcase} />}
            />
          </div>

          <div class="min-w-[280px]  ">
            <CommonCard
              title={"Total Funds"}
              count={"$1,980,345"}
              image={<FontAwesomeIcon icon={faWallet} />}
            />
          </div>
          <div class="min-w-[280px]  ">
            <CommonCard
              title={"Liquid Funds"}
              count={"$1,980,345"}
              image={<FontAwesomeIcon icon={faCoins} />}
            />
          </div>
          <div class="min-w-[280px]  ">
            <CommonCard
              title={"Fund Allocations"}
              count={"$1,980,345"}
              image={<FontAwesomeIcon icon={faDollarSign} />}
            />
          </div>
          <div class="min-w-[280px]  ">
            <CommonCard
              title={"Prospective Clients"}
              count={"$1,980,345"}
              image={<FontAwesomeIcon icon={faUserPlus} />}
            />
          </div>
        </div>

        {showModal && (
          <Modal
            centered
            isOpen={showModal}
            style={{ width: "100%", maxWidth: "1200px", padding: "0% 10%" }}
            toggle={() => {
              setShowModal(showModal => !showModal)
            }}
          >
            <ClientAdd />

            {/* <form onSubmit={handleSubmit(onSubmit)} id="custom-modal-form">
              <div className="modal-body">
                <LabelNameToInputMapper
                  errors={errors}
                  register={register}
                  labelNames={labelNames}
                  btnName={btnName}
                  setShowModal={setShowModal}
                  setttingState={setFormReturnData}
                  setCurrntAuthorization={setCurrentAuthorization}
                />
                <Row className="pt-2 ">
                  <Col>
                    <h7 className="required">Profile Image</h7>

                    <div>
                    <input
                      type="file"
                      accept="image/*"
                      required="true"
                      style={{
                        border: errors.photo1 ? "1px solid red" : "",
                      }}
                      {...register("photo1", {
                        required: "Required",
                      })}
                    ></input>
                    {
                      <FormErrorMessage
                        errorMessage={errors?.photo1?.message}
                      />
                    }
                  </div>
                  </Col>
                </Row>
                <Row className="pt-2 ">
                  <Col>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        width: "200px",
                        height: "200px",
                        border: "1px solid #bdbdbd",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        position: "relative",
                        id: "img1",
                      }}
                      onClick={onImageContainerClick}
                    >
                      <input
                        type="file"
                        required="true"
                        onChange={onImageChange}
                        style={{
                          height: "200px",
                          width: "200px",
                          zIndex: "2",
                          position: "relative",
                        }}
                        ref={fileInputRef}
                        accept="image/png, image/gif, image/jpeg, image/jpg image/tiff image/raw"
                        className="d-none"
                      />
                      <div
                        className={img.fileUrl ? "d-none" : "d-block"}
                        style={{
                          position: "absolute",

                          zIndex: "0",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ fontSize: "13px", color: "#bdbdbd" }}>
                          Drop file here <br />{" "}
                          <span className="pl-4 pr-5">or</span> <br /> Click to
                          upload
                        </p>
                      </div>
                      <img
                        src={img.fileUrl}
                        style={{
                          padding: "10px 10px",
                          objectFit: "fill",
                          height: "180px",
                          maxWidth: "180px",
                          position: "absolute",
                        }}
                        alt=""
                        className={img.fileUrl ? "d-block" : "d-none"}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div
                className="modal-footer"
                style={{ zIndex: isLoadingCreateUser ? -1 : "10" }}
              >
                {!isLoadingCreateUser && (
                  <BtnNameToButtonMapper
                    btnName={btnName}
                    setCurrentValue={setCurrentValue}
                    setShowModal={setShowModal}
                    setttingState={setFormReturnData}
                    setCurrntAuthorization={setCurrentAuthorization}
                  />
                )}
                {isLoadingCreateUser && (
                  <Button variant="success" color="success" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Loading...</span>
                  </Button>
                )}
              </div>
            </form> */}
          </Modal>
        )}

        {/* <CardHeader className="bg-primary text-white">Clients</CardHeader> */}
        {/* <div className="d-flex justify-content-right p-2 d-flex justify-content-end">
              <Button
                className="btn bg-primary "
                variant={"success"}
                color={"success"}
                type={"button"}
                onClick={() => {
                  setShowModal(() => true)
                  setCurrentAuthorization(() => false)
                }}
              >
                Add new client
              </Button>
            </div> */}

        <div
          className=" w-full flex items-center "
          style={{ minHeight: "520px" }}
        >
          <div className="flex gap-3 w-full ">
            <div className="  px-3 w-[900px]     ">
              {/* <MDBDataTable
                responsive
                striped
                noBottomColumns={true}
                searching={false}
                displayEntries={false}
                data={data}
                className="bg-fill rounded-lg"
              /> */}

              <div className="">
                <div className="bg-fill   text-title  border rounded-xl px-3 py-2">
                  <DataTable
                    className="custom-gridlines"
                    value={staticData}
                    showGridlines
                    stripedRows={true}
                    // header={header}
                    paginator
                    rows={5}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: "50rem" }}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    paginatorLeft={paginatorLeft}
                    paginatorRight={paginatorRight}
                  >
                    <Column
                      field="name"
                      header="Full Name"
                      headerStyle={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "black",

                        // background: "#F3F4F6",
                      }}
                      style={{ width: "18%" }}
                    ></Column>
                    <Column
                      field="country.name"
                      header="Phone Number"
                      headerStyle={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "black",
                        // background: "#F3F4F6",
                      }}
                      style={{ width: "18%" }}
                    ></Column>
                    <Column
                      field="company"
                      headerStyle={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "black",
                        // background: "#F3F4F6",
                      }}
                      header="Agreement Date"
                      style={{ width: "21%" }}
                    ></Column>
                    <Column
                      field="representative.name"
                      header="Commission Rate %"
                      headerStyle={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "black",
                        // background: "#F3F4F6",
                      }}
                      style={{ width: "23%" }}
                    ></Column>
                    <Column
                      field="representative.name"
                      header="Deatiled View"
                      headerStyle={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "black",
                        // background: "#F3F4F6",
                      }}
                      style={{ width: "30%" }}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
            {/* <ClientTable /> */}
            <div className="bg-fill border rounded-xl py-2   w-[45%] text-title">
              <div className="">
                <p className="px-3 py-2 font-semibold text-title  text-[18px]">
                  Activity log/ Notification
                </p>
              </div>
              {/* <div className="border"></div> */}
              {/* Activity log data */}
              <div className="px-4 bg-strippedRow">
                <div className="  flex items-center justify-between">
                  <div>
                    <p className="text-[18px] font-medium">Bitcoin</p>
                    <p className="text-[16px] font-normal">Today, 3:50 pm</p>
                  </div>
                  <div className="text-[22px] font-semibold">$47,515</div>
                </div>
                <div className="border mt-2"></div>
              </div>
              {/* Activity log data */}
              {/* Activity log data */}
              <div className="px-4 ">
                <div className=" flex items-center justify-between">
                  <div>
                    <p className="text-[18px] font-medium">Bitcoin</p>
                    <p className="text-[16px] font-normal">Today, 3:50 pm</p>
                  </div>
                  <div className="text-[22px] font-semibold">$47,515</div>
                </div>
                <div className="border mt-2"></div>
              </div>
              {/* Activity log data */}
              {/* Activity log data */}
              <div className="px-4  bg-strippedRow ">
                <div className=" flex items-center justify-between">
                  <div>
                    <p className="text-[18px] font-medium">Bitcoin</p>
                    <p className="text-[16px] font-normal">Today, 3:50 pm</p>
                  </div>
                  <div className="text-[22px] font-semibold">$47,515</div>
                </div>
                <div className="border mt-2"></div>
              </div>
              {/* Activity log data */}
              {/* Activity log data */}
              <div className="px-4  ">
                <div className=" flex items-center justify-between">
                  <div>
                    <p className="text-[18px] font-medium">Bitcoin</p>
                    <p className="text-[16px] font-normal">Today, 3:50 pm</p>
                  </div>
                  <div className="text-[22px] font-semibold">$47,515</div>
                </div>
                <div className="border mt-2"></div>
              </div>
              {/* Activity log data */}
              {/* Activity log data */}
              <div className="px-4 bg-strippedRow  ">
                <div className=" flex items-center justify-between">
                  <div>
                    <p className="text-[18px] font-medium">Bitcoin</p>
                    <p className="text-[16px] font-normal">Today, 3:50 pm</p>
                  </div>
                  <div className="text-[22px] font-semibold">$47,515</div>
                </div>
                <div className="border mt-2"></div>
              </div>
              {/* Activity log data */}
              {/* Activity log data */}
              <div className="px-4 ">
                <div className=" flex items-center justify-between">
                  <div>
                    <p className="text-[18px] font-medium">Bitcoin</p>
                    <p className="text-[16px] font-normal">Today, 3:50 pm</p>
                  </div>
                  <div className="text-[22px] font-semibold">$47,515</div>
                </div>
                <div className="border my-2"></div>
              </div>
              {/* Activity log data */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(ClientList)
