import React from "react"

export default function DatePicker(props) {
  return (
    <div className="d-flex gap-2 date-pick align-items-center mb-4   w-full">
      {props.label && <span className="label">{props.label}:</span>}
      <input
        className=" border border-black rounded-md p-[6px] w-full"
        type="date"
        {...props}
      />
      <span className="label">{props.suffix}</span>
    </div>
  )
}
