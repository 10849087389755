import CommonBasicTable from "components/UI/CommonBasicTable"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Row, Col, Modal } from "reactstrap"
import { success } from "toastr"

const Miniwidget1 = props => {
  const colVAl = props.reports?.length == 1 ? 3 : 2
  return (
    <React.Fragment>
      {props.reports.map((report, key) => (
        <Col xl={colVAl} sm={6} key={key} className="p-2">
          {console.log(report.average)}
          <Card
            style={{ cursor: "pointer" }}
            className={
              report.average > 0
                ? "bg-green-primary mini-stat "
                : report.average == 0
                ? "bg-blue-primary mini-stat "
                : "bg-red-primary mini-stat "
            }
          >
            <CardBody className="card-body mini-stat-img p-3">
              {/* so bad login here as nepse ma matra icon needed annd no time */}
              {report.iconClass && props.reports?.length == 1 && (
                <div className="mini-stat-icon ">
                  {/* <i
                    className={
                      "float-end ms-2 mdi mdi-chart-timeline-variant " +
                      report.iconClass
                    }
                  ></i> */}
                </div>
              )}
              <div
                className={
                  report.average > 0
                    ? "text-primary-color"
                    : "text-secondary-color"
                }
              >
                <div className="text-uppercase  mb-2 font-size-12 text-black">
                  {report.title}
                </div>
                <h2
                  className={
                    report.average > 0
                      ? "mb-1 text-primary-color"
                      : "mb-1 text-secondary-color"
                  }
                >
                  {report.total}
                </h2>
                {report.average && (
                  <>
                    {" "}
                    <span className={"badge bg-" + report.badgecolor}>
                      {" "}
                      {report.average}%{" "}
                    </span>{" "}
                  </>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  )
}

export default Miniwidget1
