// personalInfo.js

import React from "react"
import { Form, useForm } from "react-hook-form" // If you are using react-hook-form

const BankAccountInfo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

const onSubmit = (data) =>{
  console.log(data)
}

  return (
<form onSubmit={handleSubmit(onSubmit)}>
<div className="mt-3">
 <div className="flex justify-between gap-4 mb-4">
        <div className="flex-col flex w-full">
          <label className="m-0" htmlFor="accountName">
            Account Name
          </label>
          <input
            id="accountName"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("accountName", {
              required: "Account Name is required",
            })}
          />
        </div>
        {errors.accountName && <span>{errors.accountName.message}</span>}
        <div className="flex-col flex w-full">
          <label className="m-0" htmlFor="bankName">
          Bank Name
          </label>
          <input
            id="bankName"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("bankName", {
              required: "Bank Name is required",
            })}
          />
        </div>
        {errors.bankName && <span>{errors.bankName.message}</span>}
        
      </div>
      <div className="flex justify-between gap-4">
        <div className="flex-col flex w-full">
          <label className="m-0" htmlFor="accountNumber">
            Account Number
          </label>
          <input
            id="accountNumber"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("accountNumber", {
              required: "Account Number is required",
            })}
          />
        </div>
        {errors.accountNumber && <span>{errors.accountNumber.message}</span>}
        <div className="flex-col flex w-full">
          <label className="m-0" htmlFor="openingBalance">
          Opening Balance
          </label>
          <input
            id="openingBalance"
            className=" border border-black rounded-md p-[6px] w-full"
            {...register("openingBalance", {
              required: "Opening Balance is required",
            })}
          />
        </div>
        {errors.openingBalance && <span>{errors.openingBalance.message}</span>}
        
      </div>
   </div>
</form>
  )
}

export default BankAccountInfo
