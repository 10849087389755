import axios from "axios"

export default axios.create({
  baseURL: "https://api.vividiainfosys.com/api",
  // baseURL: "https://2ba0-202-51-69-156.ngrok-free.app/api",

  headers: {
    "content-type": "application/json",
  },
})
