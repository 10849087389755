import PropTypes from "prop-types"
import React, { useState } from "react"

import { connect } from "react-redux"
import user1 from "../../assets/images/users/user-1.jpg"

import { Link } from "react-router-dom"

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

// import megamenuImg from "../../assets/images/megamenu-img.png"
import logo from "../../assets/images/company-logo.svg"
import logoLightPng from "../../assets/images/company-logo.svg"
import logoDark from "../../assets/images/company-logo.svg"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"

const Header = props => {
  const [search, setsearch] = useState(false)
  const [createmenu, setCreateMenu] = useState(false)
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT,
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }
  var body = document.body

  function tToggle() {
    body.classList.toggle("vertical-collpsed")
    body.classList.toggle("sidebar-enable")
  }

  const handleLogoutHandler = () => {
    localStorage.setItem("token", "")
    const domain = window.location.origin
    const newUrl = domain + "/login"
    window.location.replace(newUrl)
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header w-100">
          <div className="d-flex  w-100 ">
            <div className="navbar-brand-box">
              {/* <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="100" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="70" />
                </span>
              </Link> */}

              <Link to="/" className="logo"> 
              {/* note:logo-light classname for light logo after dark mode */}
                <span className="logo-sm">
                  <img src={logo} alt="" height="20" />
                </span>
                <span className="logo-lg">
                  <img src={logoLightPng} alt="" height="70" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-24 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="mdi mdi-menu"></i>
            </button>

            {/* <div className="d-flex justify-content-end ">
              <button
                type="button"
                onClick={handleLogoutHandler}
                className="btn header-item text-danger"
              >
                Logout
              </button>
            </div> */}
          </div>
          <React.Fragment>
            <Dropdown
              isOpen={menu}
              toggle={() => setMenu(!menu)}
              className="d-inline-block"
            >
              <DropdownToggle
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                tag="button"
              >
                <img
                  className="rounded-circle header-profile-user"
                  src={user1}
                  alt="Header Avatar"
                />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {/* <div className="dropdown-divider" /> */}
                <Link to="/logout" className="dropdown-item text-danger">
                  <i
                    className="mdi mdi-power font-size-17 text-muted align-middle me-1 text-danger"
                    onClick={handleLogoutHandler}
                  />

                  <span onClick={handleLogoutHandler}>{props.t("Logout")}</span>
                </Link>
              </DropdownMenu>
            </Dropdown>
          </React.Fragment>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
